import { Target, TargetAndTransition } from 'framer-motion';

const initialBackdrop: Target = {
  opacity: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
};

const animateBackdrop: TargetAndTransition = {
  opacity: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',

  transition: {
    duration: 0.1,
    ease: 'linear',
  },
};

export const backdropAnimation = {
  initial: initialBackdrop,
  animate: animateBackdrop,
  exit: initialBackdrop,
};

const initialBodySlide: Target = {
  transform: 'translateY(100vh)',
  opacity: 0,
};

const animateBodySlide: TargetAndTransition = {
  transform: 'translateY(0%)',
  opacity: 1,
};

export const bodySlideAnimation = {
  initial: initialBodySlide,
  animate: animateBodySlide,
  exit: initialBodySlide,
};

const initialBodyFade: Target = {
  opacity: 0,
};

const animateBodyFade: TargetAndTransition = {
  opacity: 1,
};

export const bodyFadeAnimation = {
  initial: initialBodyFade,
  animate: animateBodyFade,
  exit: initialBodyFade,
};

const initialBodyZoom: Target = {
  transform: 'scale(0.8)',
  opacity: 0,
};

const animateBodyZoom: TargetAndTransition = {
  transform: 'scale(1)',
  opacity: 1,
};

export const bodyZoomAnimation = {
  initial: initialBodyZoom,
  animate: animateBodyZoom,
  exit: initialBodyZoom,
};

export const bodyAnimation = {
  slide: bodySlideAnimation,
  fade: bodyFadeAnimation,
  zoom: bodyZoomAnimation,
} as const;
