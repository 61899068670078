import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { useLayoutState } from '@/entities/layout/model';

import { Analytic, EventPlacement } from '@/shared/services/analytic';

const searchSchema = z.object({
  fromConfirm: z.boolean().optional(),
});

export const Route = createFileRoute('/_onboarded/_layout/chats')({
  validateSearch: searchSchema,
  onEnter: () => {
    useLayoutState.getState().setTitle('Chats');
    Analytic.setProperties({
      placement: EventPlacement.chats,
    });
    Analytic.dialogsListScreenView();
  },
});
