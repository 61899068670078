import TagManager from 'react-gtm-module';

const GOOGLE_TAG_MANAGER = import.meta.env.VITE_GOOGLE_TAG_MANAGER ?? 'GTM-';

export const createGTM = () => {
  const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER,
  };

  TagManager.initialize(tagManagerArgs);
};
