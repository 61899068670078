import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useDropdownContext } from '../dropdown.context';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<typeof DropdownMenu.Trigger> {
  placeholder?: string;
  error?: boolean;
}

export const Trigger = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, placeholder = 'Select an option', error, ...restProps } = props;

  const { selectedValues, isSingle } = useDropdownContext();

  const displayedValue = useMemo(() => {
    if (isSingle) {
      return selectedValues[0] || placeholder;
    }

    return selectedValues.length > 0 ? selectedValues.join(', ') : placeholder;
  }, [isSingle, selectedValues, placeholder]);

  return (
    <DropdownMenu.Trigger
      ref={ref}
      className={cn(
        'bg-grey-700 font-medium text-xl text-secondary border border-grey-500 w-full p-3 rounded-input flex justify-between items-center',
        {
          'border-brand-red focus-visible:ring-offset-brand-red focus-visible:ring-brand-red': Boolean(error),
        },
        className,
      )}
      {...restProps}
    >
      <span className="text-left overflow-hidden text-ellipsis whitespace-nowrap">{displayedValue}</span>
      {/*<ChevronDownIcon className="shrink-0" />*/}
    </DropdownMenu.Trigger>
  );
});
