import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { ThemeSchema } from '../../models';
import { IThemeService, createTheme } from '../../services';

import { createSafeContext } from '@/shared/libs/context';

const [Provider, useTheme] = createSafeContext('ThemeContext');

interface Props {
  children: ReactNode;

  theme?: ThemeSchema;
}

const ThemeProvider = (props: Props) => {
  const { children, theme } = props;

  const { 0: themeService, 1: setThemeService } = useState<IThemeService | null>(null);

  const rootMap = useMemo(() => {
    return themeService ? themeService.createRootMap() : null;
  }, [themeService]);

  useEffect(() => {
    if (theme) {
      setThemeService(createTheme(theme));
    }
  }, [theme]);

  return (
    <>
      <Helmet>{rootMap && <style>{rootMap}</style>}</Helmet>
      <Provider value={{}}>{children}</Provider>
    </>
  );
};

export { ThemeProvider, useTheme };
