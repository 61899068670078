import { HTMLAttributes } from 'react';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Footer = (props: Props) => {
  const { className, children, ...restProps } = props;

  return (
    <footer className={cn('border-t border-t-grey-600 py-4 px-3 tablet:px-6', className)} {...restProps}>
      {children}
    </footer>
  );
};
