import { CSSProperties, memo, useMemo } from 'react';

import { INavItem, NavItem, NavItemProps } from './nav-item';
import { useEnableGenerateImagePageGetter } from '@repo/common/services/features-book';

import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';

const NavigationCollection = ({ enableGenerateImage }: { enableGenerateImage: boolean }): INavItem[] => {
  const generateImage: INavItem[] = enableGenerateImage
    ? [
        {
          key: 'Generate Image',
          path: '/generate-image',
          icon: 'picture',
          label: 'Generate Image',
        },
      ]
    : [];
  return [
    {
      key: 'discover',
      path: '/discover',
      icon: 'discover',
      label: 'Discover',
    },
    {
      key: 'chats',
      path: '/chats',
      icon: 'chats',
      label: 'Chats',
    },

    {
      key: 'create-ai',
      path: '/create-ai',
      icon: 'magic-pen',
      label: 'Create AI',
    },
    ...generateImage,
  ];
};

const TapBarNavigationCollection = ({ enableGenerateImage }: { enableGenerateImage: boolean }): INavItem[] => {
  const generateImage: INavItem[] = enableGenerateImage
    ? [
        {
          key: 'Generate Image',
          path: '/generate-image',
          icon: 'picture',
          label: 'Generate Image',
        },
      ]
    : [];
  return [
    {
      key: 'discover',
      path: '/discover',
      icon: 'discover',
      label: 'Discover',
    },
    {
      key: 'chats',
      path: '/chats',
      icon: 'chats',
      label: 'Chats',
    },
    {
      key: 'create-ai',
      path: '/create-ai',
      icon: 'magic-pen',
      label: 'Create AI',
    },
    ...generateImage,
    {
      key: 'settings',
      path: '/settings',
      icon: 'user',
      label: 'Settings',
    },
  ];
};

interface Props extends Pick<NavItemProps, 'intent'> {}

export const PrimaryNavigation = memo((props: Props) => {
  const { intent = 'base' } = props;

  const enableGenerateImagePage = useEnableGenerateImagePageGetter()();

  const navigationList = useMemo(
    () =>
      intent === 'tab-bar'
        ? TapBarNavigationCollection({ enableGenerateImage: enableGenerateImagePage })
        : NavigationCollection({ enableGenerateImage: enableGenerateImagePage }),
    [intent, enableGenerateImagePage],
  );

  const stylesTabBar: CSSProperties = {
    gridTemplateColumns: `repeat(${navigationList.length}, 1fr)`,
  };

  return (
    <nav>
      <ul
        className={cn('', {
          'flex flex-col gap-3': intent === 'base',
          'grid justify-items-center gap-0.5': intent === 'tab-bar',
        })}
        style={intent === 'tab-bar' ? stylesTabBar : undefined}
      >
        {navigationList.map(({ key, ...item }) => {
          return (
            <li key={key} onClick={() => Analytic.navLinkClick(item.path)}>
              <NavItem intent={intent} {...item} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
});
