import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { ReceiveChatsResponse, ReceiveInfoMessage } from './models';
import { socketHandlerManager } from './utils';
import { webSocket } from '@/core/services/web-socket';
import { authStorage } from '@repo/modules/entity/auth/services';

import { APP_ERRORS, AppError } from '@/shared/constants';
import { ConnectSocketParams, SubscribeDisposer } from '@/shared/services/socket';

interface Subscriber {
  (data: ReceiveChatsResponse): void;

  (data: ReceiveInfoMessage): void;
}

export const useConnectSocket = () => {
  const { 0: isConnecting, 1: setIsConnecting } = useState(false);

  const memoDisposer = useRef<SubscribeDisposer | null>(null);

  const connectParams = (token: string): ConnectSocketParams => {
    return {
      opened: () => {
        if (token) {
          webSocket.send<{ token: string }>({ token });
          setIsConnecting(false);
          memoDisposer.current = webSocket.subscribe<Subscriber>(socketHandlerManager);
        }
      },
    };
  };

  const connectToSocket = (externalToken?: string) => {
    if (isConnecting) return;
    const token = externalToken ?? authStorage.getToken();
    if (!token) throw new AppError(APP_ERRORS.TOKEN_NOT_FOUND.message, APP_ERRORS.TOKEN_NOT_FOUND.code);
    webSocket.disconnect();
    setIsConnecting(true);
    webSocket.connect(connectParams(token));
  };

  const handleOnline = () => {
    connectToSocket();
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && webSocket.isClosed) {
      connectToSocket();
    }
  };

  useLayoutEffect(() => {
    const disposer = authStorage.onAuthChange((data) => {
      if (data.token) {
        connectToSocket(data.token);
      }
    });
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      disposer();
    };
  }, []);

  useEffect(() => {
    connectToSocket();
    window.addEventListener('online', handleOnline);
    return () => {
      webSocket.disconnect();
      memoDisposer.current?.();
      window.removeEventListener('online', handleOnline);
      setIsConnecting(false);
    };
  }, []);

  return { isConnecting } as const;
};
