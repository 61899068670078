import { type ApiInstance, initClient } from '@repo/api';

import { Nullable } from '@/shared/types';

export let client: ApiInstance;

export const createApiClient = (tokenExtractor?: () => Nullable<string>) => {
  const createdClient = initClient(import.meta.env.VITE_API_URL, {
    tokenExtractor,
  });

  client = createdClient;
  return createdClient;
};
