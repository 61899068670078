import { useFormContext } from 'react-hook-form';

import { SignInSchema } from '../_schema';
import { ApiError } from '@repo/api';
import { UserEntity } from '@repo/api/user';
import { authStorage } from '@repo/modules/entity/auth/services';
import { useInvalidateChatsList } from '@repo/modules/entity/chat-queries';
import { useInvalidateCharacterListQuery } from '@repo/modules/entity/chat-queries';
import { meQueryKey } from '@repo/modules/entity/user-queries';
import { useInvalidatedGetMeQuery } from '@repo/modules/entity/user-queries';

import { useSignInMutation } from '@/entities/auth/queries/use-sign-in';
import { useGetBrandConstants } from '@/entities/brand/hooks';

import { queryClient } from '@/shared/api/query-client';
import { APP_ERRORS, AppError } from '@/shared/constants';

export interface Options {
  onBeforeSubmit?: () => Promise<void> | void;
  onSuccess?: () => Promise<void> | void;
  onError?: (error: unknown) => Promise<void> | void;
}

export const useSignIn = (options?: Options) => {
  const { onBeforeSubmit, onSuccess, onError } = options || {};

  const invalidates = [useInvalidateChatsList(), useInvalidatedGetMeQuery(), useInvalidateCharacterListQuery()];

  const form = useFormContext<SignInSchema>();

  const constants = useGetBrandConstants();

  const signIn = useSignInMutation({
    onSuccess: async (data) => {
      authStorage.setToken(data.token);
      await onSuccess?.();
      await Promise.all(invalidates.map((invalidate) => invalidate()));
      const me = queryClient.getQueryData<UserEntity>(meQueryKey());
      if (me?.sub) {
        authStorage.setSub(me.sub);
      }
    },
    onError: async (error) => {
      await onError?.(error);
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      if (!constants?.api_token) {
        throw new AppError(APP_ERRORS.API_TOKEN_NOT_FOUND.message, APP_ERRORS.API_TOKEN_NOT_FOUND.code);
      }
      await onBeforeSubmit?.();
      await signIn.mutateAsync({ ...data, clientToken: constants?.api_token });
    } catch (error) {
      if (ApiError.is(error)) {
        form.setError('email', { message: error.message });
      }
      return Promise.reject(error);
    }
  });

  const isProcessingForm = signIn.isPending || form.formState.isSubmitting;

  return { form, onSubmit, isProcessingForm };
};
