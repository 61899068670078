import { memo } from 'react';

import { useDialogState } from '../../model';
import { DialogProvider } from '../../providers';
import { AnimatePresence } from 'framer-motion';

import { Portal } from '@/shared/ui';

export const DialogDistributor = memo(() => {
  const dialogsStack = useDialogState.use.stack();

  return (
    <AnimatePresence initial={false} mode="wait">
      {dialogsStack.map((dialog) => {
        if (dialog.isHidden) return null;

        const value = {
          dialogKey: dialog.key,
          onClose: dialog.close,
        };

        return (
          <Portal key={dialog.key}>
            <DialogProvider value={value}>{dialog.component}</DialogProvider>
          </Portal>
        );
      })}
    </AnimatePresence>
  );
});
