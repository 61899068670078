import { z } from 'zod';

export const signInSchema = z.object({
  email: z
    .string({ required_error: 'The specified email address is invalid' })
    .email({ message: 'The specified email address is invalid' }),
  password: z
    .string({ required_error: 'Password must not be empty' })
    .min(8, { message: 'The value must be 8 or more characters' }),
});

export type SignInSchema = z.infer<typeof signInSchema>;
