export const QUERY_KEYS = {
  Token: 'token',
  Characters: 'characters',
  GetMe: 'get-me',
  UpdateMe: 'update-me',
  Conversations: 'conversations',
  Messages: 'messages',
  CouponPacks: 'coupon-packs',
  SubscriptionList: 'subscription-list',
  SubscriptionPayment: 'subscription-payment',
  CreatePaymentIntent: 'create-payment-intent',
  FintmPacks: 'fintm-packs',
  FintmPrices: 'fintm-prices',
  CreditPacks: 'credit-packs',
  BrandConfig: 'brand-config',

  // UPDATED KEYS

  CREDIT_PACKS_LIST: 'credit-packs-list',
  SUBSCRIPTIONS_LIST: 'subscriptions-list',
  SUBSCRIPTION_PAYMENT_LOADER: 'subscription-payment-loader',
  STRIPE_CUSTOMER_LOADER: 'stripe-customer-loader',
  STRIPE_PAYMENT_INTENT_LOADER: 'stripe-payment-intent-loader',
} as const;
