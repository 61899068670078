import { ReactNode, useCallback, useLayoutEffect, useMemo, useRef } from 'react';

import { useDialogContext } from '../../providers';
import { Layout, LayoutProps } from '../../ui';

import { cn, processReactNode } from '@/shared/libs/utils';
import { Button, ButtonProps, Icon, Typography } from '@/shared/ui';

export interface Props extends Omit<LayoutProps, 'children' | 'style'> {
  actions?: ReactNode | (() => ReactNode);

  label: ReactNode;

  description?: ReactNode;

  autoClose?: boolean | number;

  icon?: ReactNode;

  variant?: 'warning' | 'success';

  hiddenOkBtn?: boolean;

  hiddenCancelBtn?: boolean;

  okTitle?: string;

  cancelTitle?: string;

  onClickOk?: () => void;

  onClickCancel?: () => void;

  okBtnProps?: Omit<ButtonProps, 'children' | 'onClick'>;

  cancelBtnProps?: Omit<ButtonProps, 'children' | 'onClick'>;
}

export const Alert = (props: Props) => {
  const {
    autoClose = false,
    icon,
    variant = 'warning',
    label,
    description,
    actions,
    className,
    placement,
    hiddenCancelBtn,
    hiddenOkBtn,
    okTitle = 'Ok',
    cancelTitle = 'Cancel',
    okBtnProps,
    cancelBtnProps,
    animation = 'zoom',
    zIndex = 'alert',
    onClose,
    onClickCancel,
    onClickOk,
    ...restProps
  } = props;

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const dialogContext = useDialogContext();

  const innerOnClose = useCallback(() => {
    onClose ? onClose() : dialogContext.onClose();
  }, [onClose]);

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const onAutoClose = () => {
    if (!autoClose) return;
    clearTimeoutRef();
    const time = typeof autoClose === 'number' ? autoClose : 3000;
    timeoutRef.current = setTimeout(() => {
      innerOnClose();
    }, time);
  };

  const innerOnClickCancel = () => {
    onClickCancel ? onClickCancel() : innerOnClose();
  };

  const innerOnClickOk = () => {
    onClickOk ? onClickOk() : innerOnClose();
  };

  const iconComp = useMemo(() => {
    if (icon || icon === null) return icon;

    switch (variant) {
      case 'warning':
        return <Icon k="warning" className="text-brand-crimson text-[3.5rem]" />;
      case 'success':
        return <Icon k="success" className="text-brand-crimson text-[3.5rem]" />;
      default:
        return null;
    }
  }, [icon]);

  const actionsComp = useMemo(() => {
    if (actions || actions === null) return processReactNode(actions);

    return (
      <>
        {!hiddenCancelBtn && (
          <Button
            {...cancelBtnProps}
            onClick={innerOnClickCancel}
            variant={cancelBtnProps?.variant ?? 'tertiary'}
            className={cn('flex-1 basis-[calc(50%-0.5rem)] w-full', cancelBtnProps?.className)}
          >
            {cancelTitle}
          </Button>
        )}
        {!hiddenOkBtn && (
          <Button
            {...okBtnProps}
            onClick={innerOnClickOk}
            variant={okBtnProps?.variant ?? 'secondary'}
            className={cn('flex-1 w-full basis-[calc(50%-0.5rem)]', okBtnProps?.className)}
          >
            {okTitle}
          </Button>
        )}
      </>
    );
  }, [
    actions,
    hiddenCancelBtn,
    hiddenOkBtn,
    onClickCancel,
    onClickOk,
    okTitle,
    cancelTitle,
    okBtnProps,
    cancelBtnProps,
  ]);

  useLayoutEffect(() => {
    onAutoClose();

    return () => {
      clearTimeoutRef();
    };
  }, []);

  return (
    <Layout
      {...restProps}
      animation={animation}
      className={cn(
        'bg-black p-4 pt-8 rounded-modal w-full [--alert-max-width:300px] tablet:[--alert-max-width:400px] max-w-[--alert-max-width]',
        className,
      )}
      placement={placement ?? 'center'}
      onClose={innerOnClose}
      zIndex={zIndex}
    >
      <div className="flex flex-col justify-center text-center">
        {iconComp && <div className="flex items-center justify-center">{iconComp}</div>}
        <Typography variant="3xl" weight="semibold" className="pt-4">
          {label}
        </Typography>
        {description && (
          <Typography variant="sm" className="text-secondary pt-1.5" weight="medium">
            {description}
          </Typography>
        )}
        <div className="flex flex-wrap gap-2 pt-6">{actionsComp}</div>
      </div>
    </Layout>
  );
};
