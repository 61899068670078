import { useRouteContext } from '@tanstack/react-router';

export const useGetBrandFeatures = () => {
  return useRouteContext({ strict: false, select: (context) => context?.brandConfig?.features });
};

export const useGetBrandConstants = () => {
  return useRouteContext({ strict: false, select: (context) => context?.brandConfig?.constants });
};

export const useGetBrandConfig = () => {
  return useRouteContext({ strict: false, select: (context) => context?.brandConfig });
};
