import { HTMLAttributes } from 'react';

import { Spinner, SpinnerKey, SpinnerProps } from '../common';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const splashScreenKey = cva(
  'top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black z-splash-screen',
  {
    variants: {
      position: {
        fixed: 'fixed',
        absolute: 'absolute',
      },
      opacity: {
        '0': 'bg-black/0',
        '30': 'bg-black/30',
        '60': 'bg-black/60',
        '80': 'bg-black/80',
        '100': 'bg-black',
      },
    },
  },
);

export interface Props extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof splashScreenKey> {
  spinnerKey?: SpinnerKey;
  spinnerClassName?: string;
  spinnerProps?: Omit<SpinnerProps, 'className' | 'k'>;
}

export const SplashScreen = (props: Props) => {
  const {
    className,
    spinnerKey = 'line-wobble',
    spinnerClassName,
    position = 'fixed',
    opacity = '100',
    spinnerProps,
    style,
    ...restProps
  } = props;

  return (
    <div className={cn(splashScreenKey({ position, opacity }), className)} {...restProps}>
      <Spinner k={spinnerKey} className={cn(spinnerClassName)} style={style} {...spinnerProps} />
    </div>
  );
};
