export const APP_ERRORS = {
  API_TOKEN_NOT_FOUND: {
    code: 'API_TOKEN_NOT_FOUND',
    message: 'API token not found',
  },
  TOKEN_NOT_FOUND: {
    code: 'TOKEN_NOT_FOUND',
    message: 'Token not found',
  },

  UNKNOWN_ERROR: {
    code: 'UNKNOWN_ERROR',
    message: 'Unknown error',
  },
} as const;

export class AppError extends Error {
  constructor(
    public message: string,
    public code?: string,
    public status?: number,
    public url?: string,
    public stack?: string,
  ) {
    super(message);

    this.name = this.constructor.name;

    if (stack) {
      this.stack = stack;
    } else {
      this.stack = new Error().stack;
    }
  }

  static create = (err: unknown) => {
    if (err instanceof AppError) {
      return err;
    }

    return new AppError('Unknown error', 'UNKNOWN_ERROR');
  };

  public toJson = () => {
    return JSON.stringify({
      message: this.message,
      code: this.code,
      status: this.status,
      url: this.url,
      stack: this.stack,
    });
  };
}
