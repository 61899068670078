import { z } from 'zod';

export const recoverPasswordSchema = z.object({
  email: z
    .string({ required_error: 'The specified email address is invalid' })
    .min(1, { message: 'Email is required' })
    .email({ message: 'The specified email address is invalid' }),
});

export type RecoverPasswordSchema = z.infer<typeof recoverPasswordSchema>;
