import { CSSProperties, ImgHTMLAttributes, SyntheticEvent, memo, useCallback, useLayoutEffect, useState } from 'react';

export interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  placeholder?: string;
  onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
  objectFit?: CSSProperties['objectFit'];
}

export const Image = memo((props: Props) => {
  const { placeholder, objectFit, style, src, onError, loading = 'lazy', alt = '', ...restProps } = props;
  const [innerSrc, setInnerSrc] = useState(src);
  const [hasError, setHasError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useLayoutEffect(() => {
    setInnerSrc(src);
    setHasError(false);
    setRetryCount(0);
  }, [src]);

  const handleError = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event>) => {
      if (retryCount < 3) {
        setRetryCount(retryCount + 1);
        setInnerSrc((prevSrc) => `${prevSrc}?retry=${retryCount}`);
      } else {
        setHasError(true);
        if (onError) {
          onError(event);
        }
      }
    },
    [retryCount, onError],
  );

  const styles = {
    ...style,
    objectFit,
  };

  return (
    <img
      {...restProps}
      style={styles}
      src={hasError && placeholder ? placeholder : innerSrc}
      onError={handleError}
      loading={loading}
      alt={alt}
    />
  );
});
