import { useGetCharacterList } from '@repo/modules/entity/chat-queries';
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

import { useConnectSocket } from '@/features/connect-socket';

import { onBoardingStorageGetters, onBoardingStorageSetters } from '@/entities/onboarding/model';

export const Route = createFileRoute('/_onboarded')({
  component: Onboarded,

  staleTime: Infinity,

  beforeLoad: ({ location, context }) => {
    const allowSkipOnboarding = context.featuresGetter<boolean>('web_allow-skip-onboarding', true);
    if (allowSkipOnboarding()) {
      onBoardingStorageSetters.setIsDone(true);
      return;
    }

    if (!onBoardingStorageGetters.getIsDone()) {
      throw redirect({
        to: '/onboarding',
        from: location.pathname,
        search: {
          redirect: location.pathname,
        },
      });
    }
  },
});

function Onboarded() {
  useGetCharacterList();
  useConnectSocket();

  return <Outlet />;
}
