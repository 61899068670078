import { ReactNode, useLayoutEffect } from 'react';

export interface Props {
  children: ReactNode;
}

const createScript = (): HTMLScriptElement => {
  const script: HTMLScriptElement = document.createElement('script');
  script.async = true;
  return script;
};

export const AppScripts = (props: Props) => {
  const { children } = props;

  const injectRedTrack = () => {
    if (import.meta.env.VITE_RED_TRACK_URL === undefined || !import.meta.env.VITE_RED_TRACK_URL.length) return;
    const script = createScript();
    script.src = import.meta.env.VITE_RED_TRACK_URL;
    document.body.appendChild(script);
  };

  const injectHotjar = () => {
    if (document.getElementById('hotjar-script')) return;

    const script = createScript();
    script.id = 'hotjar-script';
    script.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5089565,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
    document.head.appendChild(script);
  };

  useLayoutEffect(() => {
    injectRedTrack();
    injectHotjar();
  }, []);

  return <>{children}</>;
};
