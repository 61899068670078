import { createFileRoute } from '@tanstack/react-router';

import { useLayoutState } from '@/entities/layout/model';

import { Analytic, EventPlacement } from '@/shared/services/analytic';

export const Route = createFileRoute('/_onboarded/_layout/discover')({
  onEnter: () => {
    useLayoutState.getState().setTitle('Discover');
    Analytic.setProperties({
      placement: EventPlacement.discover,
    });
    Analytic.discoveryScreenView();
  },
});
