import { ComponentPropsWithoutRef, forwardRef } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<typeof DropdownMenu.Group> {}

export const CheckboxGroup = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, children, ...restProps } = props;

  return (
    <DropdownMenu.Group ref={ref} className={cn('flex flex-col gap-1', className)} {...restProps}>
      {children}
    </DropdownMenu.Group>
  );
});
