import { createFileRoute, redirect } from '@tanstack/react-router';

import { onBoardingStorageGetters, onBoardingStorageSetters } from '@/entities/onboarding/model';

export const Route = createFileRoute('/chat/character/$characterId')({
  beforeLoad: ({ context }) => {
    const { featuresGetter } = context;

    const allowRedirect = featuresGetter<boolean>('web_allow-redirect-to-particular-character', true);

    if (!allowRedirect()) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }

    if (!onBoardingStorageGetters.getIsDone()) {
      onBoardingStorageSetters.setIsDone(true);
    }
  },
});
