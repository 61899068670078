import { ReactNode } from 'react';

import { useIsPhone } from '@/shared/hooks';
import { createSafeContext } from '@/shared/libs/context';

interface SidebarValue {
  width: number;
  collapsedWidth: number;
  height: number;
}

interface TabBarValue {
  height: number;
}

interface ContextValue {
  isTablet: boolean;

  sidebar: SidebarValue;

  tabBar: TabBarValue;
}

export interface SharedPrimaryShellProviderProps {
  sidebar?: Partial<SidebarValue>;

  tabBar?: Partial<TabBarValue>;
}

interface Props extends SharedPrimaryShellProviderProps {
  children: ReactNode;
}

const defaultSidebarValues = {
  width: 240,
  collapsedWidth: 74,
  height: 68,
};

const defaultTabBarValues = {
  height: 68,
};

const [Provider, usePrimaryShell] = createSafeContext<ContextValue>('PrimaryShellContext');

const PrimaryShellProvider = (props: Props) => {
  const { children, sidebar, tabBar } = props;

  const isTablet = useIsPhone();

  const value: ContextValue = {
    isTablet,
    sidebar: { ...defaultSidebarValues, ...sidebar },
    tabBar: { ...defaultTabBarValues, ...tabBar },
  };

  return <Provider value={value}>{children}</Provider>;
};

export { PrimaryShellProvider, usePrimaryShell };
