import { HTMLAttributes, SVGProps, memo, useMemo } from 'react';

import { IconName } from './icon-names';
import loadable from '@loadable/component';

import { cn } from '@/shared/libs/utils';

export const getIcon = (k: IconName) => {
  return loadable(() => import(`@/assets/icns/${k}.svg?react`));
};

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  k: IconName;
  svgProps?: SVGProps<SVGSVGElement>;
}

export const Icon = memo((props: Props) => {
  const { k, svgProps, className, ...restProps } = props;

  const Component = useMemo(() => getIcon(k), [k]);

  return (
    <span className={cn('inline-flex items-center justify-center', className)} {...restProps}>
      <Component {...svgProps} />
    </span>
  );
});
