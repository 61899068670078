import { useCallback, useLayoutEffect, useRef } from 'react';

import { KeyboardMap } from '@/shared/constants';
import { useDialogContext } from '@/shared/ui/dialogs/providers';

interface Options {
  onClose?: () => void;

  closeOnEsc?: boolean;
}

export const useInitializeLayout = (options: Options) => {
  const { onClose, closeOnEsc } = options;

  const ref = useRef<HTMLDivElement>();

  const dialogContext = useDialogContext();

  const onInnerClose = useCallback(() => {
    onClose ? onClose() : dialogContext.onClose();
  }, [onClose]);

  const onPressEscape = (event: KeyboardEvent) => {
    if (event.key === KeyboardMap.Escape && closeOnEsc) {
      onInnerClose();
    }
  };

  useLayoutEffect(() => {
    const dialogNode = ref?.current;
    dialogNode?.focus({ preventScroll: false });

    dialogNode?.addEventListener('keydown', onPressEscape);

    return () => {
      dialogNode?.removeEventListener('keydown', onPressEscape);
    };
  }, []);

  return { ref, onInnerClose } as const;
};
