import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_onboarded/_layout/_payment')({
  component: Payment,
});

function Payment() {
  return (
    <section className="grow h-full">
      <Outlet />
    </section>
  );
}
