import '@/shared/polyfills/replace-all';

import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';

import { authStorage } from '@repo/modules/entity/auth/services';
import { UiLcpHack } from '@repo/ui-kit/ui-lcp-hack';
import 'regenerator-runtime/runtime';

import { App } from '@/app/app';

import { createApiClient } from '@/shared/api/client';
import { createGTM } from '@/shared/gtm';

import '@repo/ui-kit/styles.css';

import '@repo/modules/styles.css';

import './styles/index.css';

createGTM();

createApiClient(authStorage.getToken);

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <>
      <UiLcpHack />
      <App />
    </>,
  );
}
