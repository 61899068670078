import { UiScrollArea } from '@repo/ui-kit/ui-scroll-area';

import { useGetBrandFeatures } from '@/entities/brand/hooks';
import { warningStorage } from '@/entities/warning/model';

import { Button, Markdown } from '@/shared/ui';

// const DIALOG_KEY = 'warning';

export interface Props {
  onConfirm?: () => void;

  onLeave?: () => void;
}

export const Warning = (props: Props) => {
  const { onConfirm, onLeave } = props;
  const features = useGetBrandFeatures();

  const handleConfirmClick = () => {
    warningStorage.setWarningShown(true);
    onConfirm?.();
  };

  const handleLeaveClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.open('https://google.com', '_self', '');
    onLeave?.();
  };

  return (
    <div className="flex flex-col tablet:max-h-[670px] tablet:max-w-[550px] h-full w-full overflow-hidden">
      <div className="flex flex-col grow basis-[calc(100%-76px)] h-[calc(100%-76px)]">
        <UiScrollArea.Root className="grow">
          <UiScrollArea.Viewport>
            <div className="px-3 py-2">
              {(features as any)?.documents?.warning && <Markdown remoteSrc={(features as any)?.documents?.warning} />}
            </div>
          </UiScrollArea.Viewport>
          <UiScrollArea.Scrollbar>
            <UiScrollArea.Thumb />
          </UiScrollArea.Scrollbar>
        </UiScrollArea.Root>
      </div>
      <div className="grow basis-[76px] flex justify-center py-4 px-3 gap-2">
        <Button onClick={handleLeaveClick} variant="tertiary">
          Disagree - Exit
        </Button>
        <Button variant="secondary" onClick={handleConfirmClick}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
