import { HTMLAttributes } from 'react';

import { useCarousel } from '../carousel.context';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const slidesContainer = cva('', {
  variants: {
    gap: {
      none: 'gap-0',
      sm: 'gap-3',
      md: 'gap-5',
      xl: 'gap-8',
      'full-width': 'gap-1',
    },
    size: {
      default: 'w-auto h-auto',
      full: 'w-full h-full',
    },
  },
  defaultVariants: {
    gap: 'sm',
  },
});

export interface Props extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof slidesContainer> {
  slidesContainerClassName?: string;
}

export const SlidesContainer = (props: Props) => {
  const { className, children, gap, slidesContainerClassName, size = 'default', ...restProps } = props;

  const { carouselRef, options } = useCarousel();

  return (
    <div
      ref={carouselRef}
      className={cn(
        'overflow-hidden touch-pinch-zoom',
        {
          'touch-pan-y': options?.axis === 'x',
          'touch-pan-x': options?.axis === 'y',
          'w-full h-full': size === 'full',
        },
        className,
      )}
      role="region"
      aria-roledescription="carousel"
      {...restProps}
    >
      <div
        className={slidesContainer({
          size,
          gap,
          className: cn(
            'flex',
            {
              'flex-col': options?.axis === 'y',
            },
            slidesContainerClassName,
          ),
        })}
      >
        {children}
      </div>
    </div>
  );
};
