import { HTMLAttributes, ReactNode, useLayoutEffect } from 'react';

import { usePrimaryShell } from '../primary-shell.context';
import { usePrimaryShellState } from '../primary-shell.state';
import Logo from '@/assets/images/logo.png';
import LogoText from '@/assets/logo/logo-text.svg?react';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLElement> {
  logo?: ReactNode | (() => ReactNode);
}

export const Sidebar = (props: Props) => {
  const { className, children, style, ...restProps } = props;

  const { isTablet, sidebar } = usePrimaryShell();

  const isOpen = usePrimaryShellState.use.isOpen();
  const toggle = usePrimaryShellState.use.toggle();

  const styles = {
    ...style,
    width: isOpen ? sidebar.width : sidebar.collapsedWidth,
  };

  useLayoutEffect(() => {
    if (isTablet && isOpen) {
      toggle();
    }
  }, [isTablet]);

  return (
    <aside
      className={cn(
        'hidden tablet:block h-dvh bg-black-3 transition-size duration-300 ease-in-out rounded-e-[20px] px-3',
        {
          hidden: isTablet,
        },
        className,
      )}
      style={styles}
      {...restProps}
    >
      <header className="flex items-center py-4 border-b border-b-grey-600 transition-all">
        <div>
          <img src={Logo} alt="logo" className="min-w-[44px] h-[44px] object-center object-contain" />
        </div>
        <div
          className={cn('flex items-center  pl-2 delay-300 transition-all duration-200', {
            'opacity-0 invisible delay-0 w-0': !isOpen,
          })}
        >
          <LogoText />
        </div>
      </header>
      <div className="flex justify-end pt-3">
        <button className="flex items-center justify-center px-4 py-1 rounded-md bg-grey-500" onClick={toggle}>
          <svg
            className="transition-transform duration-300 ease-in-out"
            transform={isOpen ? 'rotate(0)' : 'rotate(180)'}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 3L7.5 8L12.5 13"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M7.5 3L2.5 8L7.5 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
      <div className="pt-6">{children}</div>
    </aside>
  );
};
