import { ApiEndpoint } from '../../types/utility';
import { BrandConfigEntity, GetBrandConfigQuery } from './entities';
import { getClient } from '../../api/api-client';
import { brandConfigEndpoints } from '../../api/api-dictionary';
import { dataExtractor } from '../../utils/data-extractor';

const getBrandConfig: ApiEndpoint<undefined, undefined, BrandConfigEntity, GetBrandConfigQuery> = (query, config) => {
  return getClient()
    .get(brandConfigEndpoints.getWebConfig(), {
      ...config,
      params: query,
    })
    .then(dataExtractor);
};

export const Repository = {
  getBrandConfig,
};
