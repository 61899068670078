import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const emailConfirmSearchSchema = z.object({
  t: z.string(),
});

export const Route = createFileRoute('/email/confirm')({
  validateSearch: emailConfirmSearchSchema,
});
