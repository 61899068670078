import { useLayoutEffect } from 'react';

import { useInvalidateFeaturesBook } from '@repo/common/services/features-book';
import { authStorage } from '@repo/modules/entity/auth/services';
import type { IAuthStorageValue } from '@repo/modules/entity/auth/services';

import { Analytic } from '@/shared/services/analytic';
import { Nullable } from '@/shared/types';

export const useWatchAuthStorageUpdating = () => {
  const invalidateFeaturesBook = useInvalidateFeaturesBook();

  const onTokenChange = ({ token }: { token?: Nullable<string> }) => {
    if (!token) return;
    invalidateFeaturesBook();
  };

  const onSubChange = ({ sub }: { sub?: Nullable<string> }) => {
    if (!sub) return;

    if (sub === Analytic.amplitude.getUserId()) return;

    Analytic.amplitude.setUserId(sub);
  };

  const onAuthChange = ({ sub, token }: Partial<IAuthStorageValue>) => {
    onTokenChange({ token });
    onSubChange({ sub });
  };

  useLayoutEffect(() => {
    const disposer = authStorage.onAuthChange(onAuthChange);

    return () => {
      disposer();
    };
  }, []);
};
