import { useSelectMe } from '@repo/modules/entity/user-queries';

import { Typography } from '@/shared/ui';

export const VerifyEmail = () => {
  const me = useSelectMe();

  return (
    <div className=" flex flex-col justify-center h-full text-center">
      <Typography as="h5" weight="bold" variant="4xl">
        Confirm your E-mail
      </Typography>
      <Typography variant="xl" className="text-secondary pt-2">
        We've sent a confirmation link <br /> to
        <Typography as="span" className="text-white  pl-1">
          {me?.email}
        </Typography>
      </Typography>
      <Typography variant="sm" className="text-secondary pt-2">
        If you don't see it, check your SPAM folder.
      </Typography>
    </div>
  );
};
