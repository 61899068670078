import { Components as MDComponents } from 'react-markdown';

export const DEFAULT_COMPONENTS: Partial<MDComponents> = {
  strong: (props) => {
    return <strong className="font-bold text-white" {...props} />;
  },
  h1: (props) => {
    return <h1 className="text-5xl font-bold py-3 text-white" {...props} />;
  },
  h2: (props) => {
    return <h2 className="text-4xl font-bold py-2 text-white" {...props} />;
  },
  h3: (props) => {
    return <h3 className="text-3xl font-bold py-1 text-white" {...props} />;
  },
  a: (props) => {
    return <a target="_blank" rel="noopener noreferrer" className="underline text-blue-300" {...props} />;
  },
  p: (props) => {
    return <p className="pt-1" {...props} />;
  },
};
