import { ChatRepository, GetMessagesParams, MessageEntity } from '@repo/api/chat';
import { InnerUseQueryOptions } from '@repo/common/types/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const KEY = 'chat-messages';

export const useGetChatMessagesQuery = (params: GetMessagesParams, options?: InnerUseQueryOptions<MessageEntity[]>) => {
  return useQuery({
    ...options,
    queryKey: [KEY, params],
    queryFn: () => ChatRepository.getChatMessages(params),
  });
};

export const useInvalidateChatMessages = () => {
  const qc = useQueryClient();
  return (params: GetMessagesParams) => qc.invalidateQueries({ queryKey: [KEY, params] });
};

export const chatMessagesQueryKey = (params: GetMessagesParams) => [KEY, params];
