import { Key } from 'react';

import { createSafeContext } from '@/shared/libs/context';

interface ContextValue {
  onClose: () => void;

  dialogKey: Key;
}

const [DialogProvider, useDialogContext] = createSafeContext<ContextValue>('DialogProvider');

export { DialogProvider, useDialogContext };
