import { Key, ReactElement } from 'react';

import { create } from 'zustand';

import { createSelectors } from '@/shared/libs/zustand-helpers';

type DialogComponent = ReactElement;

type DialogKey = Key;

type DialogStackValue = {
  key: DialogKey;

  component: DialogComponent;

  isHidden: boolean;

  close: () => void;
};

type DialogState = {
  stack: DialogStackValue[];
};

export type OpenDialogArgs = {
  key: DialogKey;

  component: DialogComponent;

  hidePrevDialogs?: boolean;

  beforeOpen?: () => void;

  afterClose?: () => void;
};

export type CloseDialogArgs = {
  key: DialogKey;

  afterClose?: () => void;
};

type OpenDialogFunc = (args: OpenDialogArgs) => (args?: Omit<CloseDialogArgs, 'key'>) => void;

type CloseDialogFunc = (args: CloseDialogArgs) => void;

type HideDialogFunc = (key: DialogKey) => void;

const initialDialogState: DialogState = {
  stack: [],
};

const useDialogBase = create<DialogState>()(() => ({
  ...initialDialogState,
}));

export const useDialogState = createSelectors(useDialogBase);

const toggleHidden: HideDialogFunc = (key) => {
  useDialogState.setState((state) => {
    const newStack = state.stack.map((item) => ({
      ...item,
      isHidden: item.key === key ? !item.isHidden : item.isHidden,
    }));
    return {
      ...state,
      stack: newStack,
    };
  });
};

const clear = () => {
  useDialogState.setState(() => initialDialogState);
};

const close: CloseDialogFunc = (args) => {
  const { key, afterClose } = args;

  new Promise((resolve) => {
    useDialogState.setState((state) => {
      const filteredStack = state.stack.filter((item) => item.key !== key);
      const finalStack = filteredStack.map((item, idx, arr) => ({
        ...item,
        isHidden: idx === arr.length - 1 ? false : item.isHidden,
      }));
      setTimeout(resolve);
      return {
        ...state,
        stack: finalStack,
      };
    });
  }).then(() => {
    afterClose?.();
  });
};

const open: OpenDialogFunc = (args) => {
  const { key, hidePrevDialogs = true, beforeOpen, afterClose, component } = args;

  new Promise((res) => {
    beforeOpen?.();
    setTimeout(res);
  }).then(() => {
    useDialogState.setState((state) => {
      const existKey = state.stack.some((item) => item.key === key);

      if (existKey) {
        return {
          ...state,
          stack: state.stack.map((item) => {
            if (item.key === key) {
              return {
                ...item,
                component,
                isHidden: false,
              };
            }
            return item;
          }),
        };
      }

      const newStack = [
        ...state.stack.map((item) => ({
          ...item,
          isHidden: hidePrevDialogs,
        })),
        {
          key,
          component,
          isHidden: false,
          close: () => close({ key, afterClose }),
        },
      ];

      return {
        ...state,
        stack: newStack,
      };
    });
  });

  return (args?: Omit<CloseDialogArgs, 'key'>) => close({ key, afterClose, ...args });
};

export const dialogControl = {
  toggleHidden,
  close,
  clear,
  open,
};
