import { createEventEmitter } from '@repo/common/services/event-emitter';
import { createStorage } from '@repo/common/services/storage';
import { Nullable } from '@repo/common/types/helpers';

export interface IAuthStorageValue {
  sub: Nullable<string>;

  token: Nullable<string>;
}

export enum AuthStorageKey {
  Auth = 'auth',
}

interface AuthEvents {
  onAuthChange: Partial<IAuthStorageValue>;
}

interface IAuthStorageValueStorage {
  [AuthStorageKey.Auth]: IAuthStorageValue;
}

const eventEmitter = createEventEmitter<AuthEvents>();
export const storage = createStorage<IAuthStorageValueStorage>(window.localStorage);

const defaultAuth: IAuthStorageValue = {
  sub: null,
  token: null,
};

const getters = {
  getAuth() {
    return storage.getItem(AuthStorageKey.Auth, defaultAuth);
  },
  getSub: () => {
    return getters.getAuth().sub;
  },
  getToken: () => {
    return getters.getAuth().token;
  },
};

const setters = {
  setAuth: (auth: Partial<IAuthStorageValue>) => {
    const val = getters.getAuth();

    eventEmitter.emit('onAuthChange', auth);

    storage.setItem(AuthStorageKey.Auth, { ...val, ...auth });
  },
  setSub: (sub: string) => {
    setters.setAuth({ sub });
  },
  setToken: (token: string) => {
    setters.setAuth({ token });
  },
  clear: () => {
    setters.setAuth(defaultAuth);
  },
};

const listeners = {
  onAuthChange: (listener: (data: Partial<IAuthStorageValue>) => void) => eventEmitter.on('onAuthChange', listener),
};

export const authStorage = {
  ...getters,
  ...setters,
  ...listeners,
} as const;
