import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@/shared/libs/utils';

export interface Props extends Omit<HTMLAttributes<HTMLFormElement>, 'children'> {
  children?: ReactNode;
  action?: ReactNode;
}

export const FormLayout = (props: Props) => {
  const { children, action, className, ...restProps } = props;
  return (
    <form className={cn('px-1', className)} {...restProps}>
      <div className="flex flex-col gap-4">{children}</div>
      <div className="pt-8">{action}</div>
    </form>
  );
};
