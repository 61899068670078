import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;

  error?: string;

  htmlFor?: string;
}

export const FieldContainer = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { className, children, label, error, htmlFor, ...restProps } = props;

  return (
    <div ref={ref} className={cn('w-full flex flex-col gap-1.5', className)} {...restProps}>
      {label && (
        <label htmlFor={htmlFor} className="pl-2 text-secondary font-semibold">
          {label}
        </label>
      )}
      {children}
      {error && (
        <Typography variant="sm" className="pl-2 text-brand-red">
          {error}
        </Typography>
      )}
    </div>
  );
});
