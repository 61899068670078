import * as Sentry from '@sentry/react';
import { Router } from '@tanstack/react-router';

export class ErrorWatcher {
  install = (router: Router<any, any>) => {
    const integrations = [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ];

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations,
      environment: import.meta.env.VITE_ENV_MODE,
      beforeSendSpan: (span) => {
        if (span.description?.includes('node_modules')) {
          return null;
        }
        return span;
      },
      tracePropagationTargets: [/^\//, import.meta.env.VITE_API_URL],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  };

  captureException = (error: unknown) => {
    Sentry.captureException(error);
  };
}

export const errorWatcher = new ErrorWatcher();
