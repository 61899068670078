import { createEventEmitter } from '@/shared/services/event-emitter';
import { StorageService } from '@/shared/services/storage';
import { Nullable } from '@/shared/types';

interface IOnboarding {
  isDone: boolean;
  intent: Nullable<string>;

  characterName: Nullable<string>;
  characterId: Nullable<number>;
}

export enum OnboardingKey {
  Onboarding = 'onboarding',
}

interface IOnboardingStorage {
  [OnboardingKey.Onboarding]: IOnboarding;
}

export const onboardingStorage = new StorageService<IOnboardingStorage>(window.localStorage);

interface OnboardingEvents {
  onOnboardingChange: Partial<IOnboarding>;
}

const OnboardingStorageEventEmitter = createEventEmitter<OnboardingEvents>();

const defaultOnboarding: IOnboarding = {
  isDone: false,
  intent: null,

  characterName: null,
  characterId: null,
};

export const onBoardingStorageGetters = {
  getOnboarding: () => onboardingStorage.getItem(OnboardingKey.Onboarding, defaultOnboarding),
  getIsDone: () => onboardingStorage.getItem(OnboardingKey.Onboarding, defaultOnboarding).isDone,
  getIntent: () => onboardingStorage.getItem(OnboardingKey.Onboarding, defaultOnboarding).intent,
  getCharacterName: () => onboardingStorage.getItem(OnboardingKey.Onboarding, defaultOnboarding).characterName,
  getCharacterId: () => onboardingStorage.getItem(OnboardingKey.Onboarding, defaultOnboarding).characterId,
};

export const onBoardingStorageSetters = {
  setOnboarding: (onboarding: Partial<IOnboarding>) => {
    const data = onboardingStorage.getItem(OnboardingKey.Onboarding, defaultOnboarding);

    OnboardingStorageEventEmitter.emit('onOnboardingChange', onboarding);

    onboardingStorage.setItem(OnboardingKey.Onboarding, { ...data, ...onboarding });
  },
  setIsDone: (isDone: boolean) => {
    onBoardingStorageSetters.setOnboarding({ isDone });
  },
  setIntent: (intent: string) => {
    onBoardingStorageSetters.setOnboarding({ intent });
  },
  setCharacterName: (characterName: string) => {
    onBoardingStorageSetters.setOnboarding({ characterName });
  },
  setCharacterId: (characterId: number) => {
    onBoardingStorageSetters.setOnboarding({ characterId });
  },
};

export const onBoardingStorageEvents = {
  onOnboardingChange: (listener: (data: Partial<IOnboarding>) => void) =>
    OnboardingStorageEventEmitter.on('onOnboardingChange', listener),
};
