import { Controller, FieldValues } from 'react-hook-form';

import { FormControlProps } from '../_types';

import { PasswordInput, type PasswordInputProps } from '@/shared/ui';

export interface Props extends PasswordInputProps {}

export const FormPassword = <T extends FieldValues>(props: Props & FormControlProps<T>) => {
  const { control, name, ...restProps } = props;
  return (
    <Controller name={name} control={control} render={({ field }) => <PasswordInput {...restProps} {...field} />} />
  );
};
