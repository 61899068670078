import { ComponentPropsWithoutRef } from 'react';

import { Range as PrimitiveRange } from '@radix-ui/react-slider';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<typeof PrimitiveRange> {}

export const Range = (props: Props) => {
  const { className, ...restProps } = props;

  return <PrimitiveRange className={cn('absolute h-full bg-brand-crimson', className)} {...restProps} />;
};
