import { TextareaHTMLAttributes, forwardRef, useMemo } from 'react';

import { cn, randomUuid } from '@/shared/libs/utils';
import { FieldContainer } from '@/shared/ui';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { className, label, error, id, name, ...restProps } = props;

  const _id = useMemo(() => {
    if (id) {
      return id;
    }
    if (name) {
      return name;
    }
    return randomUuid();
  }, [id, name]);

  return (
    <FieldContainer label={label} error={error} htmlFor={_id}>
      <textarea
        id={_id}
        ref={ref}
        className={cn(
          'border border-grey-400 p-3 scrollbar-none rounded-lg bg-black-4 ring-offset-brand-primary text-primary font-medium py-3 px-4 resize-none outline-none',
          className,
        )}
        rows={4}
        {...restProps}
      />
    </FieldContainer>
  );
});
