import { UseSignInOptions, useSignIn } from './_model';
import { SignInSchema, signInSchema } from './_schema';
import { useFeature } from '@repo/common/services/features-book';

import { GoogleSignUp } from '@/features/google-sign-up';

import { FormInput, FormPassword } from '@/entities/form/ui/field';
import { SubmitBtn } from '@/entities/form/ui/submit-btn';

import { withFormProvider } from '@/shared/hoc';
import { FormLayout } from '@/shared/ui/layouts/form-layout';

export interface Props extends UseSignInOptions {
  onClickForgotPassword?: () => void;
}

const defaultValues: SignInSchema = {
  email: '',
  password: '',
};

const _SignInForm = (props: Props) => {
  const { onClickForgotPassword, onSuccess, onError, onBeforeSubmit } = props;

  const { form, isProcessingForm, onSubmit } = useSignIn({
    onSuccess,
    onError,
    onBeforeSubmit,
  });

  const signUpWithGoogle = useFeature('sign-up-with-google', false);

  return (
    <>
      <FormLayout onSubmit={onSubmit} action={<SubmitBtn label="Log in" isProcessing={isProcessingForm} />}>
        <FormInput
          control={form.control}
          name="email"
          label="Email"
          placeholder="Enter your email"
          autoComplete="off"
          error={form.formState?.errors?.email?.message}
        />
        <div>
          <FormPassword
            control={form.control}
            name="password"
            label="Password"
            placeholder="Enter your password"
            error={form.formState?.errors?.password?.message}
          />
          <button type="button" onClick={onClickForgotPassword} className="pt-1.5 block ml-auto font-medium text-2xs">
            Forgot password?
          </button>
        </div>
      </FormLayout>
      {signUpWithGoogle && (
        <div className="w-full flex justify-center flex-wrap">
          <div className="flex items-center w-full pb-4">
            <div className="bg-grey-600 h-px w-full" />
            <span className="px-2 font-medium text-xs">or</span>
            <div className="bg-grey-600 h-px w-full" />
          </div>
          <GoogleSignUp onSuccess={onSuccess} onError={onError} onBeforeSubmit={onBeforeSubmit} />
        </div>
      )}
    </>
  );
};

export const SignInForm = withFormProvider(_SignInForm, signInSchema, defaultValues);
