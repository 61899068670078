import { useIsRegistered } from '@repo/modules/entity/user-queries';
import { useSelectMe } from '@repo/modules/entity/user-queries';
import { useLocation, useNavigate } from '@tanstack/react-router';

import { dialogAuthView } from '@/widgets/auth-view';

import { ProfileButton } from '@/entities/user/ui';

import { useIsPhone } from '@/shared/hooks';

export const ProfileAction = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isPhone = useIsPhone();

  const me = useSelectMe();
  const isRegistered = useIsRegistered();

  const onIsNotRegistered = () => {
    return new Promise((resolve) => {
      if (isRegistered) return resolve(null);
      const disposer = dialogAuthView.open({
        props: {
          onSuccessSignIn: () => {
            disposer();
            return resolve(null);
          },
        },
        options: {
          afterClose: () => resolve(null),
        },
      });
    });
  };

  const onClickProfile = async () => {
    if (!isRegistered) {
      await onIsNotRegistered();
      return;
    }

    await navigate({
      from: location.pathname,
      to: '/settings',
    });
  };

  if (isPhone) {
    return null;
  }

  return <ProfileButton className="shrink-0" onClick={onClickProfile} initials={me?.email} />;
};
