import { CSSProperties } from 'react';

import { Button, type ButtonProps, Spinner } from '@/shared/ui';

export interface Props extends Omit<ButtonProps, 'children'> {
  isProcessing?: boolean;
  label: string;
}

export const SubmitBtn = (props: Props) => {
  const { label, isProcessing, disabled, ...restProps } = props;

  const rightElement = isProcessing && (
    <Spinner
      k="simple"
      style={
        {
          '--color': '#fff',
          '--size': '16px',
          '--size-b': '2px',
        } as CSSProperties
      }
    />
  );
  return (
    <Button
      disabled={isProcessing || disabled}
      size="md"
      variant="secondary"
      type="submit"
      rightElement={rightElement}
      {...restProps}
    >
      {label}
    </Button>
  );
};
