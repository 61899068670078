import { ComponentType } from 'react';
import { DefaultValues, FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { ZodSchema } from 'zod';

export interface WithFormProviderProps<T extends FieldValues> {
  onSubmit?: SubmitHandler<T>;
  onInvalid?: SubmitErrorHandler<T>;
  defaultValues?: DefaultValues<T>;
}

export function withFormProvider<T extends FieldValues, Def extends FieldValues = T, Props extends object = {}>(
  Component: ComponentType<Props>,
  validationSchema?: ZodSchema<T>,
  initialDefaultValues?: DefaultValues<Def>,
) {
  return (props: WithFormProviderProps<T> & Props) => {
    const methods = useForm<T>({
      defaultValues: { ...(initialDefaultValues ?? {}), ...(props.defaultValues ?? {}) } as DefaultValues<T>,
      resolver: validationSchema ? zodResolver(validationSchema) : undefined,
    });

    return (
      <FormProvider {...methods}>
        {/*<form onSubmit={methods.handleSubmit(onSubmit, onInvalid)}>*/}
        <Component {...props} />
        {/*</form>*/}
      </FormProvider>
    );
  };
}
