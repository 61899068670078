import { useAuthVariant } from '../../_model';
import { AUTH_VARIANT } from '@repo/api/brand';
import { match } from 'ts-pattern';

import { Typography } from '@/shared/ui';

export interface Props {}

export const Header = (props: Props) => {
  const {} = props;

  const variant = useAuthVariant.use.variant();

  return (
    <div className="flex">
      <Typography as="h6" weight="bold" variant="4xl">
        {match(variant)
          .with(AUTH_VARIANT.SIGN_IN, () => 'Log In')
          .with(AUTH_VARIANT.SIGN_UP, () => 'Sign Up')
          .otherwise(() => null)}
      </Typography>
    </div>
  );
};
