import { HTMLAttributes } from 'react';

import { Body } from './body';
import { Footer } from './footer';
import { Header } from './header';
import { PrimaryShellProvider, SharedPrimaryShellProviderProps } from './primary-shell.context';
import { Sidebar } from './sidebar';
import { TabBar } from './tab-bar';

import { cn, mergeComponents } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement>, SharedPrimaryShellProviderProps {}

const Component = (props: Props) => {
  const { className, sidebar, ...restProps } = props;
  return (
    <PrimaryShellProvider sidebar={sidebar}>
      <div className={cn('primary-shell flex h-full supports-dvh:h-dvh bg-black', className)} {...restProps}></div>
    </PrimaryShellProvider>
  );
};

export const PrimaryShell = mergeComponents(Component, {
  Header,
  Footer,
  Sidebar,
  Body,
  TabBar,
  displayName: 'PrimaryShell',
});
