import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { onBoardingStorageGetters, onBoardingStorageSetters } from '@/entities/onboarding/model';

import { PathTo } from '@/shared/types';

const accSchema = z.union([z.literal('discover'), z.literal('chats'), z.literal('create-ai')]);

const searchSchema = z.object({
  acc: accSchema.optional(),
});

const redirectWithoutOnboarding = (path: PathTo) => {
  onBoardingStorageSetters.setIsDone(true);
  throw redirect({
    to: path,
    replace: true,
  });
};

const redirectWithOnboarding = () => {
  if (!onBoardingStorageGetters.getIsDone()) {
    throw redirect({
      to: '/onboarding',
      replace: true,
    });
  }

  throw redirect({
    to: '/chats',
    replace: true,
  });
};

export const Route = createFileRoute('/')({
  component: EntryIndexComponent,
  validateSearch: searchSchema,
  beforeLoad: ({ context }) => {
    const { featuresGetter } = context;

    const startRouter = featuresGetter<'onboarding' | 'chats' | 'discover' | 'create-ai'>(
      'web_start-router',
      'onboarding',
    );

    if (startRouter() && startRouter() !== 'onboarding') {
      redirectWithoutOnboarding(`/${startRouter()}`);
      return;
    }

    redirectWithOnboarding();
  },
});

function EntryIndexComponent() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
