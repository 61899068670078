import { useEffect, useMemo } from 'react';

import { Analytic } from '@/shared/services/analytic';
import { errorWatcher } from '@/shared/services/error-watcher';
import { Button, Typography } from '@/shared/ui';

export interface Props {
  error: unknown;
}

export const ErrorCatcher = (props: Props) => {
  const { error } = props;

  const err = useMemo(() => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(String(error));
  }, [error]);

  const onClickReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (err) {
      Analytic.somethingWentWrong(err.message);

      errorWatcher.captureException(err);

      if (err.message.includes('Failed to fetch dynamically imported module')) {
        window.location.reload();
      }
    }
  }, [err]);

  return (
    <div className="flex flex-col items-center justify-center h-dvh text-center">
      <div className="flex flex-col gap-3 max-w-[60vw]">
        <Typography as="h2" variant="5xl" weight="bold" className="text-center pb-5 ">
          Something went wrong
        </Typography>
        {import.meta.env.VITE_ENV_MODE === 'development' && (
          <pre className="text-wrap px-1 py-1.5 rounded-md border border-grey-500 bg-grey-700">{err.message}</pre>
        )}
        <div className="py-3">
          <Button variant="secondary" size="md" onClick={onClickReload}>
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
};
