import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '@/shared/api/query-client';

interface Props {
  children: ReactNode;
}

export function AppProviders(props: Props) {
  const { children } = props;
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>{children}</HelmetProvider>
    </QueryClientProvider>
  );
}
