import { ReactNode, useState } from 'react';

import { createSafeContext } from '@/shared/libs/context';

export interface DropdownContextValue {
  selectedValues: string[];
  toggleValue: (value: string) => void;
  isSingle: boolean;
}

const [DropdownProviderInternal, useDropdownContext] = createSafeContext<DropdownContextValue>('DropdownContext');

export const DropdownProvider = ({ children, isSingle }: { children: ReactNode; isSingle: boolean }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const toggleValue = (value: string) => {
    if (isSingle) {
      setSelectedValues([value]);
    } else {
      setSelectedValues((prev) => (prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]));
    }
  };

  return (
    <DropdownProviderInternal value={{ selectedValues, toggleValue, isSingle }}>{children}</DropdownProviderInternal>
  );
};

export { useDropdownContext };
