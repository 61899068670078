import { useEffect } from 'react';

import { DIALOG_KEY } from '@/core/constants';
import { paymentStorage } from '@repo/modules/entity/payment/services';
import { UiTypography } from '@repo/ui-kit/ui-typography';
import { createFileRoute } from '@tanstack/react-router';

import { Alert } from '@/shared/ui';
import { dialogControl } from '@/shared/ui/dialogs/model';

export const Route = createFileRoute('/_payment-bridge/success')({
  component: Success,
});

function Success() {
  useEffect(() => {
    dialogControl.open({
      key: DIALOG_KEY.PAYMENT_SUCCESS,
      component: <Alert variant="success" label="Your payment was successful" autoClose hiddenCancelBtn />,
      afterClose: () => {
        paymentStorage.setEpochStatusPayment('success');
        setTimeout(window.close);
      },
    });
  }, []);
  return (
    <div className="size-full flex flex-col justify-center items-center">
      <UiTypography as="h5" variant="4xl">
        Success payment
      </UiTypography>
    </div>
  );
}
