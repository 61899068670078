import { useCallback } from 'react';

import { SUBSCRIPTION_TYPE } from '@repo/api/payment';
import { useIsSubscribed } from '@repo/modules/entity/user-queries';
import { useLocation, useMatchRoute, useNavigate } from '@tanstack/react-router';

import { useIsPhone } from '@/shared/hooks';
import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { PathTo } from '@/shared/types';
import { Button, Icon } from '@/shared/ui';

const requiredHidePaths: PathTo[] = ['/subscriptions', '/create-ai/subscription'];

const optionalHidePaths: PathTo[] = ['/chats/$chatId'];

export const SubscribeAction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatchRoute();

  const isPhone = useIsPhone();

  const isSubscribed = useIsSubscribed();

  const isHidden =
    isSubscribed ||
    requiredHidePaths.some((path) => match({ to: path })) ||
    (isPhone && optionalHidePaths.some((path) => match({ to: path })));

  const onClickSubscribe = useCallback(async () => {
    Analytic.subscribeFromMainScreenClick();
    await navigate({
      from: location.pathname,
      search: {
        from: location.pathname,
        subs: SUBSCRIPTION_TYPE.PRO,
      },
      to: '/subscriptions',
    });
  }, [location.pathname]);

  return (
    <Button
      className={cn('px-3', {
        hidden: isHidden,
      })}
      onClick={onClickSubscribe}
      leftElement={<Icon k="crown" className="text-4xl tablet:text-3xl" />}
    >
      Subscribe
    </Button>
  );
};
