import { authRequests, CreateTokenDTO, TokenResponse } from '@repo/api/auth';
import { InnerUseQueryOptions } from '@repo/common/types/react-query';
import { randomId } from '@repo/common/utils/base';
import { useQuery, useQueryClient } from '@tanstack/react-query';

type Payload = {
  clientToken: string;
  version?: string;
};

const KEY = 'create-token';

export const useCreateTokenQuery = (
  { clientToken, version }: Payload,
  options?: InnerUseQueryOptions<
    TokenResponse & {
      sub: string;
    },
    unknown,
    TokenResponse & {
      sub: string;
    }
  >,
) => {
  const { initialData } = options || {};

  let sub: string;

  if (initialData && 'sub' in initialData && initialData?.sub) {
    sub = initialData.sub;
  } else {
    sub = randomId();
  }

  const dto: CreateTokenDTO = {
    client_token: clientToken,
    platform: 'web',
    version: version || '0.0.1',
    web_to_app: false,
    sub,
  };

  return useQuery({
    queryKey: [KEY],
    queryFn: async ({ signal }) => {
      const data = await authRequests.createToken(dto, { signal });
      return { ...data, sub };
    },
    initialData,
    ...options,
  });
};

export const useInvalidateTokenQuery = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries({ queryKey: [KEY] });
};
