import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { CheckboxGroup } from './checkbox-group';
import { CheckboxItem } from './checkbox-item';
import { Content } from './content';
import { DropdownProvider } from './dropdown.context';
import { Trigger } from './trigger';
import { Root } from '@radix-ui/react-dropdown-menu';

import { cn, mergeComponents } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<typeof Root> {
  isSingle?: boolean;

  className?: string;
}

const _Dropdown = forwardRef<ElementRef<typeof Root>, Props>((props, ref) => {
  const { className, children, isSingle = false, ...restProps } = props;

  return (
    <DropdownProvider isSingle={isSingle}>
      <Root {...restProps}>
        <div ref={ref} className={cn('relative flex w-full', className)}>
          {children}
        </div>
      </Root>
    </DropdownProvider>
  );
});

export const UiDropdown = mergeComponents(_Dropdown, {
  Trigger,
  Content,
  CheckboxGroup,
  CheckboxItem,
  displayName: Root.displayName,
});
