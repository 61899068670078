import { forwardRef, useMemo } from 'react';

import { Input, type InputProps } from '../input';

import { useDisclosure } from '@/shared/hooks';
import { Icon } from '@/shared/ui';

export interface Props extends Omit<InputProps, 'chevron' | 'type'> {
  isHiddenPassDefault?: boolean;
}

export const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { isHiddenPassDefault = true, ...restProps } = props;

  const [isHiddenPass, action] = useDisclosure(isHiddenPassDefault);

  const chevron = useMemo(() => {
    return (
      <button
        type="button"
        className="bg-transparent inline-flex items-center justify-center"
        onClick={action.onToggle}
      >
        <Icon k={isHiddenPass ? 'eye-hide' : 'eye-view'} />
      </button>
    );
  }, [isHiddenPass]);

  return (
    <Input autoComplete="off" chevron={chevron} type={isHiddenPass ? 'password' : 'text'} ref={ref} {...restProps} />
  );
});
