import { useAuthVariant } from '../../_model';
import { VerifyEmail } from '../verify-email';
import { AUTH_VARIANT } from '@repo/api/brand';

import { recoverPasswordDialog } from '@/widgets/recover-password-dialog';

import { SignInForm } from '@/features/sign-in-form';
import { SignUpForm } from '@/features/sign-up-form';

import { Analytic } from '@/shared/services/analytic';

export interface Props {
  onSuccess?: () => Promise<void> | void;

  onSuccessSignIn?: () => Promise<void> | void;

  onSuccessSignUp?: () => Promise<void> | void;

  onSuccessResetPassword?: () => Promise<void> | void;

  onFailed?: (error: unknown) => Promise<void> | void;

  onFailedSignIn?: (error: unknown) => Promise<void> | void;

  onFailedSignUp?: (error: unknown) => Promise<void> | void;

  onBeforeSubmit?: () => Promise<void> | void;

  onBeforeSubmitSignIn?: () => Promise<void> | void;

  onBeforeSubmitSignUp?: () => Promise<void> | void;
}

export const Form = (props: Props) => {
  const {
    onSuccess,
    onSuccessSignUp,
    onSuccessSignIn,
    onFailed,
    onFailedSignUp,
    onFailedSignIn,
    onBeforeSubmit,
    onBeforeSubmitSignIn,
    onBeforeSubmitSignUp,
  } = props;

  const variant = useAuthVariant.use.variant();

  const onSetVerifyEmail = useAuthVariant.use.onSetVerifyEmail();

  const handleSuccess = () => {
    if (variant === AUTH_VARIANT.SIGN_IN && onSuccessSignIn) {
      return onSuccessSignIn();
    }
    if (variant === AUTH_VARIANT.SIGN_UP) {
      onSetVerifyEmail();
    }
    if (variant === AUTH_VARIANT.SIGN_UP && onSuccessSignUp) {
      return onSuccessSignUp();
    }
    return onSuccess?.();
  };

  const handleFailed = (error: unknown) => {
    if (variant === AUTH_VARIANT.SIGN_IN && onFailedSignIn) {
      return onFailedSignIn(error);
    }

    if (variant === AUTH_VARIANT.SIGN_UP && onFailedSignUp) {
      return onFailedSignUp(error);
    }

    return onFailed?.(error);
  };

  const handleBeforeSubmit = () => {
    if (variant === AUTH_VARIANT.SIGN_IN && onBeforeSubmitSignIn) {
      return onBeforeSubmitSignIn();
    }

    if (variant === AUTH_VARIANT.SIGN_UP && onBeforeSubmitSignUp) {
      return onBeforeSubmitSignUp();
    }

    return onBeforeSubmit?.();
  };

  if (variant === AUTH_VARIANT.SIGN_IN) {
    Analytic.loginScreenView();
    return (
      <SignInForm
        onClickForgotPassword={() => recoverPasswordDialog.open()}
        onSuccess={handleSuccess}
        onError={handleFailed}
        onBeforeSubmit={handleBeforeSubmit}
      />
    );
  }

  if (variant === AUTH_VARIANT.SIGN_UP) {
    Analytic.signupScreenView();
    return <SignUpForm onSuccess={handleSuccess} onError={handleFailed} onBeforeSubmit={handleBeforeSubmit} />;
  }

  if (variant === AUTH_VARIANT.VERIFY_EMAIL) {
    return <VerifyEmail />;
  }

  if (variant === AUTH_VARIANT.RESET_PASSWORD) {
    return null;
  }

  return null;
};
