import { ReceiveChatsResponse, ReceiveInfoMessage, ResponseSocket } from '../models';
import type { MessageEntity } from '@repo/api/chat';
import type { UserEntity } from '@repo/api/user';
import { chatMessagesQueryKey, chatsListQueryKey } from '@repo/modules/entity/chat-queries';
import { meQueryKey } from '@repo/modules/entity/user-queries';

import { queryClient } from '@/shared/api/query-client';

export const updateChats = (event: ReceiveChatsResponse) => {
  const { chats } = event;

  queryClient.setQueryData(chatsListQueryKey(), chats);

  const availableCoinsAmount = chats?.[0] ? chats[0].credits : null;
  const isSubscribed = chats?.[0] ? chats[0].is_subscribed : false;

  queryClient.setQueryData(meQueryKey(), (viewer: UserEntity) => {
    if (!viewer) return viewer;
    return viewer
      ? {
          ...viewer,
          subscription_plan: chats?.[0]?.subscription_plan ?? viewer.subscription_plan,
          credits: typeof availableCoinsAmount === 'number' ? availableCoinsAmount : viewer.credits,
          is_subscribed: isSubscribed,
        }
      : viewer;
  });

  for (const chat of chats) {
    const queryKey = chatMessagesQueryKey({ chatId: chat.id.toString() });

    if (!chat.last_message) continue;
    const messages: MessageEntity[] = queryClient.getQueryData(queryKey) ?? [];

    const lastMessage = messages[messages.length - 1];
    const isExistMessage = messages.some((message) => message.id === chat.last_message?.id);

    if (!isExistMessage) {
      queryClient.setQueryData(queryKey, (prev: MessageEntity[] | undefined) => {
        const _prev = prev ?? [];
        if (lastMessage?.sender === chat.last_message?.sender && lastMessage.body === chat.last_message?.body) {
          return _prev;
        }

        return [..._prev, chat.last_message];
      });
    }
  }
};

const isChatsEvent = (event: ResponseSocket): event is ReceiveChatsResponse => {
  return Object.keys(event).includes('chats');
};

const isMessageEvent = (event: ResponseSocket): event is ReceiveInfoMessage => {
  return Object.keys(event).includes('message');
};

export const socketHandlerManager = (data: ResponseSocket) => {
  if (isChatsEvent(data)) {
    updateChats(data);
    return;
  }

  if (isMessageEvent(data)) {
    return;
  }
};
