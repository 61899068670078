import { createFileRoute } from '@tanstack/react-router';

import { Analytic } from '@/shared/services/analytic';

export const Route = createFileRoute('/_onboarded/_layout/_settings/settings')({
  // component: Settings,
  onEnter: () => {
    Analytic.settingsScreenView();
  },
});

// function Settings() {
//   return (
//     <div className="grow h-full">
//       <Outlet />
//     </div>
//   );
// }
