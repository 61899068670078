import { QUERY_KEYS } from '@/core/constants';
import { BrandConfigEntity, BrandRepository, GetBrandConfigQuery } from '@repo/api/brand';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { InnerUseQueryOptions, queryClient } from '@/shared/api/query-client';

export const useGetBrandConfig = (query?: GetBrandConfigQuery, options?: InnerUseQueryOptions<BrandConfigEntity>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.BrandConfig],
    queryFn: () => {
      return BrandRepository.getBrandConfig(query);
    },
    ...options,
  });
};

export const useSuspenseGetBrandConfig = (
  query?: GetBrandConfigQuery,
  options?: InnerUseQueryOptions<BrandConfigEntity>,
) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.BrandConfig],
    queryFn: () => {
      return BrandRepository.getBrandConfig(query);
    },
    ...options,
  });
};

export const invalidateBrandConfigQuery = () => {
  return queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BrandConfig] });
};
