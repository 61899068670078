import { HTMLAttributes } from 'react';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLElement> {
  withTabBar?: boolean;
  withSidebar?: boolean;
}

export const Body = (props: Props) => {
  const { className, ...restProps } = props;

  return <div className={cn('h-dvh grow overflow-clip transition-all flex flex-col', className)} {...restProps} />;
};
