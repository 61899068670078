import { createEventEmitter } from '@/shared/services/event-emitter';
import { StorageService } from '@/shared/services/storage';

enum WarningKey {
  Warning = 'warning',
}

interface IWarning {
  isShown: boolean;
}

interface IWarningStorage {
  [WarningKey.Warning]: IWarning;
}

interface WarningStorageEvents {
  onWarningChange: Partial<IWarning>;
}

const WarningStorageEventEmitter = createEventEmitter<WarningStorageEvents>();

const defaultWarning: IWarning = {
  isShown: false,
};

class WarningStorage extends StorageService<IWarningStorage> {
  constructor() {
    super(window.localStorage);
  }

  getWarning = () => {
    return this.getItem(WarningKey.Warning, defaultWarning);
  };

  isWarningShown = () => {
    return this.getWarning().isShown;
  };

  setWarning = (warning: Partial<IWarning>) => {
    const currentWarning = this.getWarning();
    WarningStorageEventEmitter.emit('onWarningChange', warning);
    this.setItem(WarningKey.Warning, { ...currentWarning, ...warning });
  };

  setWarningShown = (isShown: boolean) => {
    this.setWarning({ isShown });
  };

  clear = () => {
    this.setWarning(defaultWarning);
  };
}

export const warningStorage = new WarningStorage();
