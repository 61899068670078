import { type UseSignUpOptions, useSignUp } from './_model';
import { SignUpSchema, signUpSchema } from './_schema';
import { ActionBtn } from './_ui';
import { useFeature } from '@repo/common/services/features-book';

import { useGetBrandConstants } from '@/entities/brand/hooks';
import { FormInput, FormPassword } from '@/entities/form/ui/field';

import { withFormProvider } from '@/shared/hoc';
import { FormLayout } from '@/shared/ui/layouts/form-layout';

export interface Props extends UseSignUpOptions {}

const defaultValues: SignUpSchema = {
  email: '',
  password: '',
};

const _SignUpForm = (props: Props) => {
  const { onSuccess, onError, onBeforeSubmit } = props;

  const { form, isProcessingForm, onSubmit } = useSignUp({
    onSuccess,
    onError,
    onBeforeSubmit,
  });

  const constants = useGetBrandConstants();

  const signUpWithGoogle = useFeature('sign-up-with-google', false);

  const isGoogleSignUpEnabled = signUpWithGoogle && constants?.firebase_web_keys?.client_id;

  return (
    <FormLayout
      onSubmit={onSubmit}
      action={
        <ActionBtn
          onSuccess={onSuccess}
          onError={onError}
          onBeforeSubmit={onBeforeSubmit}
          isGoogleSignUp={isGoogleSignUpEnabled}
          isProcessingForm={isProcessingForm}
        />
      }
    >
      <FormInput
        control={form.control}
        name="email"
        label="Email"
        placeholder="Enter your email"
        autoComplete="off"
        error={form.formState?.errors?.email?.message}
      />
      <FormPassword
        control={form.control}
        name="password"
        label="Password"
        placeholder="Enter your password"
        error={form.formState?.errors?.password?.message}
      />
    </FormLayout>
  );
};

export const SignUpForm = withFormProvider(_SignUpForm, signUpSchema, defaultValues);
