import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCredential } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';

type FirebaseParams = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

const firebaseConfig: FirebaseParams = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMEN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

class FirebaseService {
  private messaging: any;

  private auth: any;

  constructor() {
    this.initializeFirebase();
    this.registerServiceWorker();
  }

  public async getCurrentUserToken(credential: any) {
    try {
      await signInWithCredential(this.auth, credential);
      return this.auth.currentUser.getIdToken(true);
    } catch (error) {
      console.error('An error occurred while retrieving user: ', error);
      throw error;
    }
  }

  public async getToken() {
    if (!import.meta.env.VITE_FIREBASE_PUBLIC_KEY) return;

    try {
      const registration = await this.registerServiceWorker();

      if (registration?.active?.state === 'activated') {
        const token = await getToken(this.messaging, {
          vapidKey: import.meta.env.VITE_FIREBASE_PUBLIC_KEY,
          serviceWorkerRegistration: registration,
        });

        if (token) {
          return token;
        } else {
          console.log('No registration token available. Request permission to generate one.');
          return null;
        }
      }
    } catch (err) {
      console.error('An error occurred while retrieving token: ', err);
    }
  }

  private initializeFirebase() {
    try {
      if (!firebaseConfig.projectId) return;
      const app = initializeApp(firebaseConfig);
      this.auth = getAuth(app);
      this.messaging = getMessaging(app);
      console.log('Firebase initialized.');
    } catch (error) {
      console.error('Firebase initialization error: ', error);
    }
  }

  private async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        return await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    } else {
      console.error('Service workers are not supported in this browser.');
    }
  }
}

export const firebaseService = new FirebaseService();
