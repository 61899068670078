import { enableGenerateImagePageKey } from '@repo/common/services/features-book';
import { redirect } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

import { useLayoutState } from '@/entities/layout/model';

export const Route = createFileRoute('/_onboarded/_layout/_generate-image/generate-image')({
  beforeLoad: ({ context }) => {
    const { featuresGetter } = context;

    const val = featuresGetter(enableGenerateImagePageKey(), true);

    if (!val()) {
      throw redirect({ to: '/discover' });
    }
  },

  onEnter: () => {
    useLayoutState.getState().setTitle('Generate Image');
  },
});
