import { create } from 'zustand';

import { createSelectors } from '@/shared/libs/zustand-helpers';

type State = {
  isOpen: boolean;
  pageName: string;
};

type Actions = {
  open: () => void;
  close: () => void;
  toggle: () => void;
  setPageName: (pageName: string) => void;
};

const defaultState: State = {
  isOpen: false,
  pageName: '',
};

const usePrimaryShellStateBase = create<State & Actions>()((set) => ({
  ...defaultState,
  open: () => set((state) => ({ ...state, isOpen: true })),
  close: () => set((state) => ({ ...state, isOpen: false })),
  toggle: () => set((state) => ({ ...state, isOpen: !state.isOpen })),
  setPageName: (pageName) => set((state) => ({ ...state, pageName })),
}));

export const usePrimaryShellState = createSelectors(usePrimaryShellStateBase);
