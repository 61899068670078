import { ButtonHTMLAttributes } from 'react';

import { cn } from '@/shared/libs/utils';
import { Icon } from '@/shared/ui';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

export interface InnerProps extends Props {
  isNextBtn?: boolean;
}

export const NavButton = (props: InnerProps) => {
  const { className, isNextBtn, ...restProps } = props;
  return (
    <button
      className={cn(
        'flex items-center justify-center bg-white/20 rounded-md size-9 text-4xl backdrop-blur-lg',
        'disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...restProps}
    >
      <Icon
        k="arrow"
        className={cn({
          'transform rotate-180': isNextBtn,
        })}
      />
    </button>
  );
};

// /* Button Base */
//
// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 6px 24px;
// gap: 2px;
//
// position: absolute;
// width: 38px;
// height: 36px;
// left: 12px;
// top: 241px;
//
// background: rgba(255, 255, 255, 0.2);
// backdrop-filter: blur(15px);
// /* Note: backdrop-filter has minimal browser support */
// border-radius: 8px;
