import { useEffect, useRef } from 'react';

import { extractCookieValue } from '@repo/common/utils/helpers';
import { useUpdateMeMutation } from '@repo/modules/entity/user-queries';

import { CookiesDictionary } from '@/shared/constants/cookies-dictionary';

export const useRedtrackClickId = () => {
  const updateMe = useUpdateMeMutation();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (import.meta.env.VITE_RED_TRACK_URL === undefined || !import.meta.env.VITE_RED_TRACK_URL.length) return;

    const handleClickId = async () => {
      if (import.meta.env.VITE_RED_TRACK_URL === undefined || !import.meta.env.VITE_RED_TRACK_URL.length) return;

      let rtkclickid = extractCookieValue(CookiesDictionary.RED_TRACK);

      if (rtkclickid) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        await updateMe.mutateAsync({
          rtkclickid_store: rtkclickid,
        });
      }
    };

    intervalRef.current = setInterval(() => {
      handleClickId();
    }, 200);

    handleClickId();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);
};
