import { ResetPasswordDTO, authRequests } from '@repo/api/auth';
import { useMutation } from '@tanstack/react-query';

import { InnerUseMutationOptions } from '@/shared/api/query-client';

export const useRecoverPasswordMutation = (config?: InnerUseMutationOptions<unknown, unknown, ResetPasswordDTO>) => {
  return useMutation({
    mutationFn: (dto) => authRequests.resetPassword(dto),
    ...config,
  });
};
