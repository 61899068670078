import { Controller, FieldValues } from 'react-hook-form';

import { FormControlProps } from '../_types';

import { Input, type InputProps } from '@/shared/ui';

export interface Props extends InputProps {}

export const FormInput = <T extends FieldValues>(props: Props & FormControlProps<T>) => {
  const { control, name, ...restProps } = props;
  return <Controller name={name} control={control} render={({ field }) => <Input {...restProps} {...field} />} />;
};
