import { UiIcon } from '@repo/ui-kit/ui-icon';
import type { UiIconDictionary } from '@repo/ui-kit/ui-icon';
import { Link } from '@tanstack/react-router';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';
import { PathTo } from '@/shared/types';
import { Typography, usePrimaryShellState } from '@/shared/ui';

const navItem = cva(
  'group/link flex items-center gap-2 text-grey-300 transition-colors data-[status=active]:text-white',
  {
    variants: {
      intent: {
        base: 'p-3 border border-grey-500 rounded-2md',
        'tab-bar': 'flex-col',
      },
    },
    defaultVariants: {
      intent: 'base',
    },
  },
);

export interface INavItem {
  key: string;
  path: PathTo;
  icon: UiIconDictionary;
  label: string;
}

export interface Props extends Omit<INavItem, 'key'>, VariantProps<typeof navItem> {}

export const NavItem = (props: Props) => {
  const { path, icon, label, intent = 'base' } = props;

  const isOpenSidebar = usePrimaryShellState.use.isOpen();

  return (
    <Link to={path} data-intent={intent} className={cn(navItem({ intent }))}>
      <UiIcon k={icon} className="text-4xl group-data-[intent=tab-bar]/link:text-[clamp(24px,7vw,32px)]" />
      <Typography
        as="span"
        variant="xl"
        weight="semibold"
        className={cn(
          'inline-block transition-all delay-150 overflow-clip whitespace-nowrap group-data-[intent=tab-bar]/link:text-xs',
          {
            'opacity-0 invisible delay-0 h-px': !isOpenSidebar && intent !== 'tab-bar',
          },
        )}
      >
        {label}
      </Typography>
    </Link>
  );
};
