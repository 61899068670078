import { useCallback, useState } from 'react';

import { DIALOG_KEY } from '@/core/constants';

import { RecoverPasswordForm } from '@/features/recover-password-form';

import { Alert, Modal, Typography } from '@/shared/ui';
import { CloseDialogArgs, OpenDialogArgs, dialogControl } from '@/shared/ui/dialogs/model';

const RecoverPasswordDialog = () => {
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null);

  const onBeforeSubmit = useCallback((email?: string) => {
    setSubmittedEmail(email ?? null);
  }, []);

  const onSuccess = useCallback(() => {
    dialogControl.close({ key: DIALOG_KEY.RECOVER_PASSWORD });
    dialogControl.open({
      key: DIALOG_KEY.SUCCESS_RECOVER_PASSWORD,
      component: (
        <Alert
          label="Mail sent"
          hiddenCancelBtn
          variant="success"
          description={
            <>
              Please check your inbox <Typography className="text-white">{submittedEmail}</Typography>
            </>
          }
        />
      ),
    });
  }, [submittedEmail]);

  return (
    <Modal
      placement="center"
      className="bg-black rounded-modal px-4 pb-6 pt-2 w-full max-w-[93.4vw] tablet:max-w-[400px]"
    >
      <div className="text-center pt-[40px] pb-4">
        <Typography weight="bold" variant="4xl" className="text-primary">
          Password Recovery
        </Typography>
        <Typography weight="medium" className="text-secondary pt-2 max-w-[288px] mx-auto">
          Enter your e-mail and we will send you the instructions to reset password
        </Typography>
      </div>
      <RecoverPasswordForm onSuccess={onSuccess} onBeforeSubmit={onBeforeSubmit} />
    </Modal>
  );
};

export const recoverPasswordDialog = {
  open: (params?: { options?: Omit<OpenDialogArgs, 'component' | 'key'> }) =>
    dialogControl.open({
      key: DIALOG_KEY.RECOVER_PASSWORD,
      component: <RecoverPasswordDialog />,
      ...params?.options,
    }),
  close: (options?: Omit<CloseDialogArgs, 'key'>) =>
    dialogControl.close({ key: 'recover-password-dialog', ...options }),
};
