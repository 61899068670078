import { AuthContentEntity } from '@repo/api/brand';

import { Nullable } from '@/shared/types';

export const getAuthPicture = (content: Nullable<AuthContentEntity>, intent: Nullable<string>) => {
  if (!content) return null;

  const { pictures } = content;

  if (!pictures.intent || !Object.keys(pictures.intent) || !intent) return pictures.default;

  return pictures?.intent?.[intent] ?? pictures.default;
};
