import { Suspense } from 'react';

import { LayoutActions } from './-ui/layout-actions';
import { PrimaryNavigation } from '@/widgets';
import { Outlet, createFileRoute, useMatchRoute, useRouter } from '@tanstack/react-router';
import { AnimatePresence } from 'framer-motion';

import { useLayoutState } from '@/entities/layout/model';

import { cn } from '@/shared/libs/utils';
import { PathTo } from '@/shared/types';
import { Button, Icon, PrimaryShell, SplashScreen, Typography } from '@/shared/ui';

export const Route = createFileRoute('/_onboarded/_layout')({
  component: Layout,
});

const tabBarHidePaths: PathTo[] = ['/chats/$chatId', '/subscriptions', '/credits', '/create-ai/subscription'];

const headerHidePaths: PathTo[] = ['/chats/$chatId', '/create-ai/subscription'];

const includeBackButtonPaths: PathTo[] = ['/credits', '/subscriptions', '/settings'];

const actionsHiddenPaths: PathTo[] = ['/subscriptions', '/credits'];

function Layout() {
  const route = useRouter();
  const matchRoute = useMatchRoute();

  const layoutTitle = useLayoutState.use.title();

  const isHiddenTabBar = tabBarHidePaths.some((path) => matchRoute({ to: path }));
  const isHiddenHeader = headerHidePaths.some((path) => matchRoute({ to: path }));
  const isIncludeBackButton = includeBackButtonPaths.some((path) => matchRoute({ to: path }));
  const isActionHidden = actionsHiddenPaths.some((path) => matchRoute({ to: path }));

  const label = () => {
    return (
      <div className="flex items-center">
        {isIncludeBackButton && (
          <Button
            size="icon-xs"
            variant="outline-secondary"
            className="tablet:hidden"
            onClick={() => route.history.back()}
          >
            <Icon k="arrow" className="text-brand-crimson text-3xl" />
          </Button>
        )}
        {layoutTitle && (
          <Typography
            as="h2"
            weight="bold"
            variant="3xl"
            className={cn('tablet:text-5xl tablet:pl-0 ', { 'pl-3': isIncludeBackButton })}
          >
            {layoutTitle}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <Suspense fallback={<SplashScreen spinnerKey="line-wobble" />}>
      <PrimaryShell>
        <PrimaryShell.Sidebar>
          <PrimaryNavigation />
        </PrimaryShell.Sidebar>
        <PrimaryShell.Body>
          <PrimaryShell.Header
            className={cn({ 'hidden tablet:flex': isHiddenHeader })}
            actions={<LayoutActions isHidden={isActionHidden} />}
            label={label}
          />
          <AnimatePresence initial={false} mode="wait">
            <div className="flex flex-col grow overflow-y-auto">
              <Outlet />
            </div>
          </AnimatePresence>
          {!isHiddenTabBar && (
            <PrimaryShell.TabBar>
              <PrimaryNavigation intent="tab-bar" />
            </PrimaryShell.TabBar>
          )}
        </PrimaryShell.Body>
      </PrimaryShell>
    </Suspense>
  );
}
