// import InternetConnection from '@/assets/icons/internet-connection.svg?react';
import { useCallback } from 'react';

import { DIALOG_KEY } from '@/core/constants';
import { useEventListener } from 'usehooks-ts';

import { Button, Icon } from '@/shared/ui';
import { dialogControl } from '@/shared/ui/dialogs/model';
import { Alert } from '@/shared/ui/dialogs/pub';

export const useListenInternetConnection = () => {
  const onClickRefresh = useCallback(() => {
    window.location.reload();
  }, []);
  const offlineHandler = () => {
    dialogControl.open({
      key: DIALOG_KEY.NO_INTERNET,
      component: (
        <Alert
          hideCloseBtn
          variant="warning"
          closeOnEsc={false}
          label="No internet connection"
          description="Make sure you are connected to Wi-Fi or your mobile network!"
          icon={
            <span className="font-primary text-[56px]">
              <Icon k="internet-connection" />
            </span>
          }
          actions={
            <div className="w-full pt-6">
              <Button onClick={onClickRefresh} className="w-full" size="lg">
                Refresh
              </Button>
            </div>
          }
        />
      ),
    });
  };

  const onlineHandler = () => {
    dialogControl.close({ key: DIALOG_KEY.NO_INTERNET });
  };

  useEventListener('offline', offlineHandler);
  useEventListener('online', onlineHandler);
};
