import { ReactNode } from 'react';

import { Nullable } from '@/shared/types';

export interface Props {
  header?: ReactNode;
  form: ReactNode;
  footer?: ReactNode;
  pictureSrc?: Nullable<string>;
}

export const Layout = (props: Props) => {
  const { form, footer, header, pictureSrc } = props;

  return (
    <div className="flex flex-col tablet:grid tablet:grid-cols-2 tablet:w-full h-full">
      <div
        className="relative before:gradient-overlay basis-[47%] tablet:w-full shrink bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: pictureSrc ? `url(${pictureSrc})` : '',
        }}
      />
      <div className="relative z-1 -top-[20px] tablet:top-0 flex flex-col grow shrink-0 w-full">
        <div className="pb-2 tablet:py-4 pl-4">{header}</div>
        <div className="flex flex-col gap-3 justify-between px-4 pt-3 pb-6 grow ">
          {form}
          {footer && <div className="pt-4 border-t border-t-grey-600">{footer}</div>}
        </div>
      </div>
    </div>
  );
};
