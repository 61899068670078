import { create } from 'zustand';

import { createSelectors } from '@/shared/libs/zustand-helpers';
import { Nullable } from '@/shared/types';

interface State {
  title: Nullable<string>;
}

interface Actions {
  setTitle: (title: Nullable<string>) => void;
}

const defaultState: State = {
  title: null,
};

const useLayoutStateBase = create<State & Actions>()((set) => ({
  ...defaultState,
  setTitle: (title) => set((state) => ({ ...state, title })),
}));

export const useLayoutState = createSelectors(useLayoutStateBase);
