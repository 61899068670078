import { useEffect } from 'react';
import { useMemo } from 'react';

import { routeTree } from '@/routeTree.gen';
import { RouteContext } from '@/routes/__root';
import { ErrorCatcher } from '@/widgets';
import { BrandPayloadEntity } from '@repo/api/brand';
import { UserEntity } from '@repo/api/user';
import { useFeatures, useFeaturesBookStatus } from '@repo/common/services/features-book';
import type { ModuleConfigValue } from '@repo/modules/config';
import { ModuleConfigProvider } from '@repo/modules/config';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { dialog } from '@repo/ui-kit/ui-dialogs';
import { RouterProvider, createRouter } from '@tanstack/react-router';

import { Warning } from '@/features/_dialogs/warning';
import { useListenInternetConnection } from '@/features/listen-internet-connection';
import { useWatchAuthStorageUpdating } from '@/features/watch-auth-storage';

import { useGetBrandConfig } from '@/entities/brand/queries/use-get-brand-config';
import { useRedtrackClickId } from '@/entities/redtrack';
import { warningStorage } from '@/entities/warning';

import { queryClient } from '@/shared/api/query-client';
import { SplashScreen } from '@/shared/ui';

export const router = createRouter({
  routeTree: routeTree,
  defaultPendingComponent: () => <SplashScreen spinnerKey="line-wobble" />,
  context: {
    queryClient: queryClient,
    brandConfig: {} as BrandPayloadEntity & {
      version: number;
    },
    title: '',
    me: {} as UserEntity,
    featuresGetter: (() => () => undefined) as RouteContext['featuresGetter'],
  },
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: ErrorCatcher,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function AppRouter() {
  const brandQuery = useGetBrandConfig();
  const meQuery = useGetMeQuery();

  const features = useFeatures();

  const status = useFeaturesBookStatus();

  const context = {
    brandConfig: useMemo(() => ({ version: brandQuery.data?.version, ...brandQuery.data?.payload }), [brandQuery.data]),
    me: useMemo(() => meQuery.data, [meQuery.data]),
    featuresGetter: <T,>(key: string, defaultValue?: T): (() => T) => {
      let val: T;
      return () => {
        if (val !== undefined) return val;
        val = features[key]?.value;
        return val || (defaultValue as T);
      };
    },
  } as RouteContext;

  const moduleConfig = useMemo(
    (): ModuleConfigValue => ({
      stripe: {
        publicKey: import.meta.env.VITE_STRIPE_KEY,
        appearance: {
          theme: 'night',
        },
      },
    }),
    [],
  );

  useRedtrackClickId();
  useListenInternetConnection();
  useWatchAuthStorageUpdating();

  useEffect(() => {
    if (!warningStorage.isWarningShown() && Boolean((context as any).brandConfig?.features?.documents?.warning)) {
      const disposer = dialog.open({
        id: 'warning',
        content: (
          <Warning
            onConfirm={() => {
              disposer();
            }}
          />
        ),
      });
    }
  }, []);

  if (status === 'loading') {
    return <SplashScreen spinnerKey="line-wobble" />;
  }

  return (
    <ModuleConfigProvider value={moduleConfig}>
      <RouterProvider router={router} context={context} />
    </ModuleConfigProvider>
  );
}
