import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/docs/$doc')({
  beforeLoad: ({ context, location }) => {
    const paths = context.brandConfig.features?.documents_links || [];
    const availablePaths = paths?.map((item) => item?.to) || [];

    if (!availablePaths.includes(location.pathname)) {
      throw redirect({ to: '/' });
    }
  },
});
