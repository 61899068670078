import { CSSProperties, HTMLAttributes, ReactNode } from 'react';

import { useInitializeLayout } from './use-initialize-layout';
import { VariantProps, cva } from 'class-variance-authority';
import { HTMLMotionProps, motion } from 'framer-motion';

import { ValueByMedia, useMediaValue, useMergeRefs } from '@/shared/hooks';
import { cn } from '@/shared/libs/utils';
import { Icon } from '@/shared/ui';
import { backdropAnimation, bodyAnimation } from '@/shared/ui/dialogs/ui/layout/animation';

const layout = cva(null, {
  variants: {
    zIndex: {
      modal: 'z-modal',
      alert: 'z-alert',
    },
    withBackdrop: {
      true: null,
    },
    rounded: {
      true: 'rounded-modal',
    },
    animation: {
      slide: '',
      fade: '',
      zoom: '',
    },
  },
});

const closeButton = cva(
  'absolute p-1.5 flex items-center justify-center bg-white/30 text-white rounded-[5px] text-[8px]',
  {
    variants: {
      hideCloseBtn: {
        true: 'hidden',
      },
      placementCloeBtn: {
        'top-left': 'top-4 left-4',
        'top-right': 'top-4 right-4',
        'bottom-left': 'bottom-4 left-4',
        'bottom-right': 'bottom-4 right-4',
      },
    },
  },
);

type Placement = 'center' | 'bottom' | 'top';

const PlacementValues: Record<Placement, string> = {
  top: 'justify-start',
  bottom: 'justify-end',
  center: 'justify-center',
} as const;

export interface Props
  extends VariantProps<typeof layout>,
    VariantProps<typeof closeButton>,
    Omit<HTMLMotionProps<'div'>, 'style'> {
  backdropProps?: HTMLMotionProps<'button'>;

  onClose?: () => void;

  py?: ValueByMedia<number>;

  px?: ValueByMedia<number>;

  fullSize?: ValueByMedia<boolean>;

  placement?: ValueByMedia<Placement>;

  style?: ValueByMedia<CSSProperties>;

  children?: ReactNode;

  closeBtnProps?: Omit<HTMLAttributes<HTMLButtonElement>, 'children'>;

  closeOnEsc?: boolean;

  innerRef?: (node: HTMLDivElement) => void;
}

export const Layout = (props: Props) => {
  const {
    zIndex = 'modal',
    withBackdrop = true,
    placement,
    backdropProps,
    children,
    className,
    py,
    px,
    style,
    animation = 'slide',
    placementCloeBtn = 'top-right',
    hideCloseBtn = false,
    closeBtnProps,
    closeOnEsc = true,
    fullSize,
    onClose,
    innerRef,
    ...restProps
  } = props;

  const { ref, onInnerClose } = useInitializeLayout({
    onClose,
    closeOnEsc,
  });

  const refs = useMergeRefs(innerRef, ref);

  const [pyValue, pxValue, fullSizeValue, placementValue, styleValue] = [
    useMediaValue(py, 24),
    useMediaValue(px, 24),
    useMediaValue(fullSize, false),
    useMediaValue(placement, {
      desktop: 'center',
      phone: 'bottom',
    }),
    useMediaValue(style, {}),
  ] as const;

  const rootStyle = {
    '--padding-block': `${pyValue / 16}rem`,
    '--padding-inline': `${pxValue / 16}rem`,
  } as CSSProperties;

  const styles = {
    ...(styleValue ?? {}),
  };

  return (
    <div
      style={rootStyle}
      className={cn(
        'fixed inset-0 flex flex-col items-center md:py-[--padding-block] md:px-[--padding-inline]',
        layout({
          zIndex,
        }),
        PlacementValues[placementValue ?? 'center'],
        {
          'p-0': fullSizeValue,
        },
      )}
    >
      {withBackdrop && (
        <motion.button
          {...backdropAnimation}
          {...backdropProps}
          onClick={onInnerClose}
          type="button"
          className={cn(
            'backdrop absolute w-full h-full inset-0 bg-black/50 backdrop-blur-sm',
            backdropProps?.className,
          )}
        />
      )}

      <motion.div
        tabIndex={0}
        ref={refs}
        className={cn(
          'relative overflow-hidden max-h-dvh',
          'focus-visible:ring-0 outline-none focus-visible:ring-offset-0',
          {
            'w-full h-full supports-dvh:h-dvh': fullSizeValue,
          },
          className,
        )}
        {...bodyAnimation[animation ?? 'slide']}
        style={styles}
        {...restProps}
      >
        {!hideCloseBtn && (
          <button
            {...closeBtnProps}
            type="button"
            className={cn('z-2', closeButton({ hideCloseBtn, placementCloeBtn }), closeBtnProps?.className)}
            onClick={onInnerClose}
          >
            <Icon k="cross" />
          </button>
        )}

        {children}
      </motion.div>
    </div>
  );
};
