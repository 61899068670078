import { DefaultError, QueryClient, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

export type InnerQueryError = DefaultError;

export type InnerUseQueryOptions<T, Err = InnerQueryError, Data = T> = Partial<UseQueryOptions<T, Err, Data>>;

export type InnerUseMutationOptions<T, Err = InnerQueryError, Var = void> = Partial<UseMutationOptions<T, Err, Var>>;
