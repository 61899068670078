import { HTMLAttributes, ReactNode, memo } from 'react';

import { cn, processReactNode } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLElement> {
  label?: ReactNode | (() => ReactNode);
  actions?: ReactNode | (() => ReactNode);
}

export const Header = memo((props: Props) => {
  const { className, actions, label, ...restProps } = props;

  const labelNode = processReactNode(label);
  const actionsNode = processReactNode(actions);

  return (
    <header
      className={cn(
        'primary-shell-header flex flex-col justify-center p-3 basis-[68px] h-[68px] border-b border-b-grey-500',
        className,
      )}
      {...restProps}
    >
      <div
        className={cn('flex items-center justify-between', {
          'justify-end': actionsNode && !labelNode,
        })}
      >
        {labelNode}
        {/*<Typography as="h2" weight="bold" variant="5xl">*/}
        {/*  {pageName}*/}
        {/*</Typography>*/}
        {actionsNode}
      </div>
    </header>
  );
});
