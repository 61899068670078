import type { SpinnerProps } from '@/shared/ui/common/spinner/types';

export const CircleKey = 'circle';

export interface Props extends SpinnerProps {
  size?: number;
}

export const Circle = (props: Props) => {
  const { size = 40, ...restProps } = props;
  return (
    <span {...restProps}>
      <svg className="origin-center will-change-transform" viewBox={`0 0 ${size} ${size}`} height={size} width={size}>
        <circle className="track" cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="5px" fill="none" />
        <circle className="car" cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="5px" fill="none" />
      </svg>
    </span>
  );
};
