import { HTMLAttributes, useLayoutEffect, useState } from 'react';

import { CarouselApi, useCarousel } from '../carousel.context';
import { NavButton } from '../nav-button';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const NavButtons = (props: Props) => {
  const { className, ...restProps } = props;

  const [available, setAvailable] = useState({
    next: false,
    prev: false,
  });

  const { carouselApi } = useCarousel();

  const onSelect = (api: CarouselApi) => {
    setAvailable({
      next: api.canScrollNext(),
      prev: api.canScrollPrev(),
    });
  };

  useLayoutEffect(() => {
    if (!carouselApi) return;

    onSelect(carouselApi);
    carouselApi.on('reInit', onSelect).on('select', onSelect);
  }, [carouselApi]);

  return (
    <div className={cn('flex w-full items-center justify-between pointer-events-none', className)} {...restProps}>
      <NavButton
        className="prev-btn pointer-events-auto"
        onClick={() => carouselApi?.scrollPrev()}
        disabled={!available.prev}
      />
      <NavButton
        className="next-btn pointer-events-auto"
        onClick={() => carouselApi?.scrollNext()}
        isNextBtn
        disabled={!available.next}
      />
    </div>
  );
};
