import { useEffect } from 'react';

import { type BrandPayloadEntity } from '@repo/api/brand';
import { UserEntity } from '@repo/api/user';
import { useIsRegistered } from '@repo/modules/entity/user-queries';
import { UiDialogContainer } from '@repo/ui-kit/ui-dialogs';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useSearch } from '@tanstack/react-router';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { z } from 'zod';

import { dialogAuthView } from '@/widgets/auth-view';

import { DialogDistributor, SplashScreen } from '@/shared/ui';

export interface RouteContext {
  queryClient: QueryClient;
  brandConfig: BrandPayloadEntity & {
    version: number;
  };
  title: string;
  me: UserEntity;
  featuresGetter: <T>(key: string, defaultValue?: T) => () => T;
}

const search = z.object({
  auth: z.boolean().optional(),
});

export const Route = createRootRouteWithContext<RouteContext>()({
  component: RootRoute,
  pendingComponent: () => <SplashScreen spinnerKey="line-wobble" />,
  validateSearch: search,
});

function RootRoute() {
  const isRegistered = useIsRegistered();
  const search = useSearch({ strict: false });

  // useEffect(() => {
  //   unlockPictureDialog.open({
  //     pictureSrc:
  //       'https://softpear-static.s3.eu-central-1.amazonaws.com/v1/image/3252ef9f-8ebc-4ed1-8a38-f106e16ac35b.jpg',
  //   });
  // }, []);

  useEffect(() => {
    let disposer: () => void;
    if (!isRegistered && search?.auth) {
      disposer = dialogAuthView.open();
    }

    return () => {
      disposer?.();
    };
  }, [search, isRegistered]);

  return (
    <>
      <main className="h-full supports-dvh:h-dvh overflow-clip">
        <Outlet />
      </main>
      <DialogDistributor />
      <UiDialogContainer />
      {/* <TanStackRouterDevtools position="bottom-right"  /> */}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </>
  );
}
