import { Nullable } from '@/shared/types';
import { Button, type ButtonProps, Icon, Typography } from '@/shared/ui';

export interface Props extends ButtonProps {
  initials?: Nullable<string>;
}

export const ProfileButton = (props: Props) => {
  const { initials, ...restProps } = props;

  const truncatedInitials = initials?.slice(0, 2).toUpperCase();

  return (
    <Button size="icon-sm" variant="outline-secondary" rounded="lg" {...restProps}>
      {initials ? <Typography>{truncatedInitials}</Typography> : <Icon k="user" className="text-4xl" />}
    </Button>
  );
};
