import { GoogleSignUp } from '@/features/google-sign-up';

import { SubmitBtn } from '@/entities/form/ui/submit-btn';

import { Typography } from '@/shared/ui';

export interface Props {
  isProcessingForm: boolean;
  isGoogleSignUp: boolean;
  onBeforeSubmit?: () => Promise<void> | void;
  onSuccess?: () => Promise<void> | void;
  onError?: (error: unknown) => Promise<void> | void;
}

export const ActionBtn = (props: Props) => {
  const { isProcessingForm, isGoogleSignUp, onSuccess, onError, onBeforeSubmit } = props;

  return (
    <div className="flex flex-col">
      <SubmitBtn label="Create Account" isProcessing={isProcessingForm} />
      {isGoogleSignUp && (
        <div className="w-full pt-4 flex justify-center flex-wrap">
          <div className="flex items-center w-full pb-4">
            <div className="bg-grey-600 h-px w-full" />
            <span className="px-2 font-medium text-xs">or</span>
            <div className="bg-grey-600 h-px w-full" />
          </div>
          <GoogleSignUp onSuccess={onSuccess} onError={onError} onBeforeSubmit={onBeforeSubmit} />
        </div>
      )}
      <Typography weight="medium" variant="sm" className="text-center text-secondary pt-3">
        By signing up, you agree to our{' '}
        <Typography weight="medium" variant="sm" as="span" className="text-white">
          Terms of Use
        </Typography>
      </Typography>
    </div>
  );
};
