import { ButtonHTMLAttributes } from 'react';

import { Slot, Slottable } from '@radix-ui/react-slot';
import { ReactNode } from '@tanstack/react-router';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const button = cva(
  `
  relative
  flex items-center justify-center text-center gap-1.5 overflow-clip
  transition-all duration-300
  font-semibold
  disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none
  px-6
  `,
  {
    variants: {
      variant: {
        primary: 'bg-brand-gradient text-brand-brown ui-kit-button-primary',
        secondary: 'bg-brand-crimson text-white ui-kit-button-secondary',
        tertiary: 'bg-grey-500 text-white',
        outline: 'bg-black text-white border border-brand-crimson',
        'outline-secondary': 'bg-black text-white border border-grey-500',
        ghost: 'bg-transparent text-white',
        light: 'bg-brand-light text-black',
        action: 'bg-black border border-grey-500 text-brand-crimson',
        black: 'bg-black-3 text-white',
      },

      w: {
        full: 'w-full',
        auto: 'w-auto',
        fit: 'w-fit',
      },

      size: {
        xs: 'min-h-7 text-2sm',
        sm: 'min-h-9 text-sm',
        md: 'min-h-11 px-4 text-xl',
        lg: 'min-h-14 px-8 text-xl',
        xl: 'min-h-20 px-8 text-2xl',
        icon: 'w-11 h-11 px-3',
        'icon-xs': 'size-10 py-1.5 px-1',
        'icon-sm': 'size-12 py-1.5 px-1',
        'icon-lg': 'size-14 px-1',
      },

      rounded: {
        xs: 'rounded-button-xs',
        sm: 'rounded-button-sm',
        md: 'rounded-button-md',
        lg: 'rounded-button-lg',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      rounded: 'md',
      w: 'full',
    },
  },
);

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof button> {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  asChild?: boolean;
}

export const Button = (props: Props) => {
  const {
    className,
    asChild = false,
    variant,
    size,
    children,
    rounded,
    w,
    leftElement,
    rightElement,
    ...restProps
  } = props;

  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      className={cn(button({ variant, size, rounded, w }), 'font-semibold text-base md:text-xl', className)}
      {...restProps}
    >
      {leftElement}
      <Slottable>{children}</Slottable>
      {rightElement}
    </Comp>
  );
};
