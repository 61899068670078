import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { onBoardingStorageGetters } from '@/entities/onboarding/model';

const onBoardingSearchSchema = z.object({
  intent: z.string().optional(),
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/_onboarding')({
  component: Onboarding,
  validateSearch: onBoardingSearchSchema,
  beforeLoad: () => {
    if (onBoardingStorageGetters.getIsDone()) {
      throw redirect({
        to: '/',
      });
    }
  },
});

function Onboarding() {
  return <Outlet />;
}
