import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { Analytic } from '@/shared/services/analytic';

const search = z.object({
  withProfile: z.boolean().optional(),
});

export const Route = createFileRoute('/_onboarded/_layout/chats/$chatId')({
  validateSearch: search,
  onEnter: () => {
    Analytic.chatScreenView();
  },
});
