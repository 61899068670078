import { Suspense } from 'react';

import { AppInitialize } from './app-initialize';
import { AppProviders } from './app-providers';
import { AppRouter, router } from './app-router';
import { AppScripts } from './app-scripts';
import { GlobalFeatureFlagProvider } from '@repo/common/services/features-book';
import { ErrorBoundary, withProfiler } from '@sentry/react';

import { errorWatcher } from '@/shared/services/error-watcher';
import { SplashScreen } from '@/shared/ui';

errorWatcher.install(router);

function _App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<SplashScreen spinnerKey="line-wobble" />}>
        <AppProviders>
          <AppScripts>
            <AppInitialize>
              <GlobalFeatureFlagProvider>
                <AppRouter />
              </GlobalFeatureFlagProvider>
            </AppInitialize>
          </AppScripts>
        </AppProviders>
      </Suspense>
    </ErrorBoundary>
  );
}

export const App = withProfiler(_App);
