import { SignUpDTO, TokenResponse, authRequests } from '@repo/api/auth';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { CookiesDictionary } from '@/shared/constants/cookies-dictionary';
import { extractCookieValue } from '@/shared/libs/utils';

export const useSignUpMutation = (options?: Partial<UseMutationOptions<TokenResponse, unknown, SignUpDTO>>) => {
  const redTrackId = extractCookieValue(CookiesDictionary.RED_TRACK);
  return useMutation({
    ...options,
    mutationFn: (dto) =>
      authRequests.signUp({
        ...dto,
        ...(redTrackId && { rtkclickid_store: redTrackId }),
      }),
  });
};
