export enum DIALOG_KEYS {
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  EMAIL_CONFIRM = 'EMAIL_CONFIRM',
}

// NEW DIALOG KEYS
export enum DIALOG_KEY {
  STRIPE_SUBSCRIPTION_CHECKOUT_FORM = 'STRIPE_SUBSCRIPTION_CHECKOUT_FORM',
  STRIPE_CREDIT_CHECKOUT_FORM = 'STRIPE_STRIPE_CHECKOUT_FORM',

  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',

  WARNING_DELETING_CREATED_CHARACTER = 'WARNING_DELETING_CREATED_CHARACTER',

  AUTH_VIEW = 'AUTH_VIEW',

  CREDIT_PACKS_VIEW = 'CREDIT_PACKS_VIEW',

  NO_INTERNET = 'NO_INTERNET',

  EMAIL_CONFIRM = 'EMAIL_CONFIRM',

  RECOVER_PASSWORD = 'RECOVER_PASSWORD',

  SUCCESS_RECOVER_PASSWORD = 'SUCCESS_RECOVER_PASSWORD',

  SUBSCRIPTION_PAYWALL = 'SUBSCRIPTION_PAYWALL',

  CANCEL_SUBSCRIPTION_VIEW = 'CANCEL_SUBSCRIPTION_VIEW',

  CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS',

  FILE_SIZE_LIMIT = 'FILE_SIZE_LIMIT',
}
