import ReactGA from 'react-ga4';

import * as amplitude from '@amplitude/analytics-browser';
import type { PaymentEventPayload } from './dto';
import {
  AddToCart,
  EventPlacement,
  PaywallView,
  PersonId,
  Placement,
  ProcessPayment,
  PurchaseFailed,
  PurchaseSuccessful,
  SendMessage,
} from './dto';
import { EVENT_COLLECTION, type EventCollectionName } from './event-collection';
import { Identify } from '@amplitude/analytics-browser';

import { Nullable, PathTo } from '@/shared/types';

export type AnalyticServiceParams = {
  amplitudeKey?: string;
  googleTrackingId?: string;
};

export class AnalyticService {
  static amplitude = amplitude;

  private static placement = EventPlacement.unknown;

  private static personId: Nullable<number> = null;

  private static personName: Nullable<string> = null;

  static init = ({ googleTrackingId, amplitudeKey }: AnalyticServiceParams) => {
    if (amplitudeKey && amplitudeKey !== 'empty') {
      amplitude.init(amplitudeKey, { defaultTracking: true });
    }
    if (googleTrackingId && googleTrackingId !== 'empty') {
      ReactGA.initialize([{ trackingId: googleTrackingId }]);
    }
  };

  static setUserId = (userId: string) => {
    if (userId === amplitude.getUserId()) return;
    amplitude.setUserId(userId);
  };

  static firstLaunch = () => {
    this.firstTouch(window.location.href);
    this.dispatch(EVENT_COLLECTION.first_launch);
  };

  static ratingFeedbackView = (chatId: string | number) => {
    this.dispatch(EVENT_COLLECTION.experience_feedback_view, {
      chat_id: chatId.toString(),
    });
  };

  static ratingFeedbackSubmitClick = (value: { chatId: string | number; rating: number; comment?: string }) => {
    this.dispatch(EVENT_COLLECTION.feedback_submit_click, {
      chat_id: value.chatId.toString(),
      rating: value.rating,
      comment: value.comment,
    });
  };

  static onboardingScreen = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.quiz_onboarding_step_view, step));
  };

  static onboardingContinueClick = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.quiz_onboarding_step_continue_click, step));
  };

  static clickMainCreateAiBanner = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_main_banner_click);
  };

  static onboardingPersonaReady = () => {
    this.dispatch<PersonId>(EVENT_COLLECTION.quiz_onboarding_persona_ready_view, {
      persona_name: this.personName ?? '',
      persona_id: this.personId ?? 0,
    });
  };

  static dialogsListScreenView = () => {
    this.dispatch(EVENT_COLLECTION.dialogs_list_screen_view);
  };

  static personaSelectView = () => {
    this.dispatch(EVENT_COLLECTION.quiz_onboarding_persona_select_view);
  };

  static subscribeFromMainScreenClick = () => {
    this.dispatch(EVENT_COLLECTION.subscribe_from_main_screen_click);
  };

  static conversationClick = (dto: Partial<PersonId> = {}) => {
    this.dispatch<PersonId>(EVENT_COLLECTION.dialog_click, {
      persona_name: this.personName ?? '',
      persona_id: this.personId ?? 0,
      ...dto,
    });
  };

  static chatScreenView = () => {
    this.dispatch(EVENT_COLLECTION.chat_screen_view);
  };

  static chatCouponsBalanceCLick = () => {
    this.dispatch(EVENT_COLLECTION.chat_coupons_balance_click);
  };

  static photoUnblurClick = () => {
    this.dispatch(EVENT_COLLECTION.photo_unblur_click);
  };

  static sendMessageClick = (dto?: Partial<SendMessage>) => {
    this.dispatch<PersonId>(EVENT_COLLECTION.send_message_click, {
      persona_name: this.personName ?? '',
      persona_id: this.personId ?? 0,
      ...dto,
    });
  };

  static createAiStepView = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.create_ai_step_view, step));
  };

  static createAiStepContinueClick = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.create_ai_step_continue_click, step));
  };

  static createAiPersonaReadyView = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_persona_ready_view);
  };

  static createAiPersonaReadyContinueClick = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_persona_ready_continue_click);
  };

  static createAiPersonaInProgressView = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_persona_inprogress_view);
  };

  static signupScreenView = () => {
    this.dispatch(EVENT_COLLECTION.signup_screen_view);
  };

  static signupCloseClick = () => {
    this.dispatch(EVENT_COLLECTION.signup_close_click);
  };

  static signupCreateAccountClick = () => {
    this.dispatch(EVENT_COLLECTION.signup_create_account_click);
  };

  static loginScreenView = () => {
    this.dispatch(EVENT_COLLECTION.login_screen_view);
  };

  static loginClick = () => {
    this.dispatch(EVENT_COLLECTION.login_click);
  };

  static subscribeFromCoinsPaywallClick = () => {
    this.dispatch(EVENT_COLLECTION.subscribe_from_coins_paywall_click);
  };

  static personaProfileView = () => {
    this.dispatch(EVENT_COLLECTION.persona_profile_view);
  };

  static editProfileCLick = () => {
    this.dispatch(EVENT_COLLECTION.edit_profile_click);
  };

  static personaEditProfileView = () => {
    this.dispatch(EVENT_COLLECTION.persona_edit_profile_view);
  };

  static addFaceClick = () => {
    this.dispatch(EVENT_COLLECTION.edit_profile_add_face_click);
  };

  static finishEditProfileClick = () => {
    this.dispatch(EVENT_COLLECTION.finish_edit_profile_click);
  };

  static paywallScreenView = (data?: Partial<PaywallView>) => {
    this.dispatch(EVENT_COLLECTION.paywall_screen_view, {
      placement: this.placement,
      person_id: this.personId ?? 0,
      person_name: this.personName ?? '',
      ...data,
    });
  };

  static paywallSkipClick = () => {
    this.dispatch<Placement>(EVENT_COLLECTION.paywall_skip_click, {
      placement: this.placement,
    });
  };

  static checkoutEmailSentSuccess = () => {
    this.dispatch(EVENT_COLLECTION.checkout_success_email_sent_click);
  };

  static addToCart = (dto: Omit<AddToCart, 'placement'> & { placement?: EventPlacement }) => {
    this.dispatch<AddToCart>(EVENT_COLLECTION.add_to_cart, { ...dto, placement: dto.placement ?? this.placement });
  };

  static beginCheckout = (placement: EventPlacement) => {
    this.dispatch<Placement>(EVENT_COLLECTION.begin_checkout, { placement });
  };

  static processPaymentClick = (dto: ProcessPayment) => {
    this.dispatch<ProcessPayment>(EVENT_COLLECTION.process_payment_click, dto);
  };

  static closeCheckoutClick = () => {
    this.dispatch(EVENT_COLLECTION.close_checkout_click);
  };

  static discoveryScreenView = () => {
    this.dispatch(EVENT_COLLECTION.discover_screen_view);
  };

  static purchaseSuccessful = (dto: Omit<PurchaseSuccessful, 'placement'> & { placement?: EventPlacement }) => {
    this.dispatch<PurchaseSuccessful>(EVENT_COLLECTION.purchase, {
      ...dto,
      placement: dto.placement ?? this.placement,
    });
  };

  static purchaseFailed = (dto: Omit<PurchaseFailed, 'placement'> & { placement?: EventPlacement }) => {
    this.dispatch<PurchaseFailed>(EVENT_COLLECTION.purchase_failed, {
      ...dto,
      placement: dto.placement ?? this.placement,
    });
  };

  static getPurchaseEvents = <Payload extends Partial<PaymentEventPayload> = {}>(payload?: Payload) => {
    return {
      add: (data: Omit<AddToCart, keyof Payload>) => AnalyticService.addToCart({ ...payload, ...data } as AddToCart),

      success: (data: Omit<PurchaseSuccessful, keyof Payload>) =>
        AnalyticService.purchaseSuccessful({ ...payload, ...data } as PurchaseSuccessful),

      failed: (data: Omit<PurchaseFailed, keyof Payload>) =>
        AnalyticService.purchaseFailed({ ...payload, ...data } as PurchaseFailed),
    };
  };

  static discoverNoProfilesLeft = () => {
    this.dispatch(EVENT_COLLECTION.discover_no_profiles_left);
  };

  static navLinkClick = (path: PathTo) => {
    switch (path) {
      case '/chats':
        this.dialogsListClick();
        break;
      case '/discover':
        this.discoverClick();
        break;
      case '/settings':
        this.settingsClick();
        break;
      case '/create-ai':
        this.createAiClick();
        break;
      default:
        break;
    }
  };

  static discoverClick = () => {
    this.dispatch(EVENT_COLLECTION.discover_click);
  };

  static settingsClick = () => {
    this.dispatch(EVENT_COLLECTION.settings_click);
  };

  static dialogsListClick = () => {
    this.dispatch(EVENT_COLLECTION.dialogs_list_click);
  };

  static createAiClick = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_click);
  };

  static checkoutSuccess = () => {
    this.dispatch(EVENT_COLLECTION.checkout_success);
  };

  static cancelSubscriptionClick = () => {
    this.dispatch(EVENT_COLLECTION.cancel_subscription_click);
  };

  static cancelSubscriptionFlowView = () => {
    this.dispatch(EVENT_COLLECTION.cancel_subscription_flow_view);
  };

  static cancelSubscriptionFlowUnsubscribeClick = (reason: string) => {
    this.dispatch(EVENT_COLLECTION.cancel_subscription_flow_unsubscribe_click, { reason: reason });
  };

  static subscriptionRenewalCanceled = () => {
    this.dispatch(EVENT_COLLECTION.subscription_renewal_canceled);
  };

  static settingsScreenView = () => {
    this.dispatch(EVENT_COLLECTION.settings_screen_view);
  };

  static subscribeFromSettingsScreenClick = () => {
    this.dispatch(EVENT_COLLECTION.subscribe_from_settings_screen_click);
  };

  static quizDeleteProgressView = () => {
    this.dispatch(EVENT_COLLECTION.quiz_onboarding_delete_progress_view);
  };

  static somethingWentWrong = (error: unknown) => {
    this.amplitudeTrack(EVENT_COLLECTION.something_went_wrong, { error });
  };

  static pushNotificationEnabled = () => {
    const identify = new Identify();
    identify.setOnce(EVENT_COLLECTION.push_notification_enabled, 'enabled');
    amplitude.identify(identify);
  };

  static pushNotificationDisabled = () => {
    const identify = new Identify();
    identify.setOnce(EVENT_COLLECTION.push_notification_disabled, 'disabled');
    amplitude.identify(identify);
  };

  static pushNotificationOpen = () => {
    this.dispatch(EVENT_COLLECTION.push_open);
  };

  static firstTouch = (url: string) => {
    const identify = new Identify();
    identify.setOnce(EVENT_COLLECTION.first_touch_url, url);
    amplitude.identify(identify);
    ReactGA.gtag('set', 'user_properties', { [EVENT_COLLECTION.first_touch_url]: url });
  };

  static setProperties = (properties: { personName?: string; personId?: number; placement?: EventPlacement }) => {
    if (!Object.keys(properties).length) return;
    this.personName = properties.personName ?? this.personName;
    this.personId = properties.personId ?? this.personId;
    this.placement = properties.placement ?? this.placement;
  };

  private static amplitudeTrack = (event: EventCollectionName, params?: Record<string, unknown>) => {
    amplitude.track(event, params);
  };

  private static reactGaTrack = (event: EventCollectionName, params?: Record<string, unknown>) => {
    ReactGA.event(event, params);
  };

  private static dispatch = <T extends Record<string, unknown>>(event: EventCollectionName, params?: T) => {
    this.amplitudeTrack(event, params);
    this.reactGaTrack(event, params);
  };

  private static replaceDynamicEvent = <T extends string | number>(event: EventCollectionName, value: T) => {
    return event.replace(/{{.*}}/gm, `${value}`) as EventCollectionName;
  };
}

AnalyticService.init({
  googleTrackingId: import.meta.env.VITE_GOOGLE_TRACKING_ID,
  amplitudeKey: import.meta.env.VITE_AMPLITUDE_KEY,
});
