import { HTMLAttributes } from 'react';

import { usePrimaryShell } from '../primary-shell.context';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const TabBar = (props: Props) => {
  const { className, children, ...restProps } = props;

  const { isTablet } = usePrimaryShell();

  return (
    <div
      className={cn(
        'py-2.5 px-2 max-h-[68px] grow shrink basis-[68px] h-[68px] border-t border-t-grey-600',
        {
          hidden: !isTablet,
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};
