import { useAuthVariant } from '../../_model';
import { AUTH_VARIANT } from '@repo/api/brand';

import { Analytic } from '@/shared/services/analytic';
import { Typography } from '@/shared/ui';

export const Footer = () => {
  const variant = useAuthVariant.use.variant();
  const onsSetVariant = useAuthVariant.use.onSet();

  const handleVariant = () => {
    if (variant === AUTH_VARIANT.SIGN_IN) {
      Analytic.signupCreateAccountClick();
      return onsSetVariant(AUTH_VARIANT.SIGN_UP);
    }
    Analytic.loginClick();
    return onsSetVariant(AUTH_VARIANT.SIGN_IN);
  };

  return (
    <div className="flex justify-center items-center">
      <Typography variant="2xs" weight="medium" className=" text-secondary pr-1">
        {variant === AUTH_VARIANT.SIGN_IN ? 'Don`t have an account yet?' : 'Already have an account yet?'}
      </Typography>
      <button onClick={handleVariant} className="font-semibold text-2xs">
        {variant === AUTH_VARIANT.SIGN_IN ? 'Sign up' : 'Sign in'}
      </button>
    </div>
  );
};
