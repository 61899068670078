import { SUBSCRIPTION_PLAN, SUBSCRIPTION_TYPE } from '@repo/api/payment';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { useLayoutState } from '@/entities/layout/model';

const subscriptionSearchSchema = z.object({
  subs: z.nativeEnum(SUBSCRIPTION_TYPE).catch(SUBSCRIPTION_TYPE.PRO),
  from: z.string().optional(),
});

export const Route = createFileRoute('/_onboarded/_layout/_payment/subscriptions')({
  validateSearch: subscriptionSearchSchema,
  beforeLoad: ({ context, search }) => {
    const from = search?.from ?? '/discover';
    const isSubscriptionProPlus =
      search?.subs === SUBSCRIPTION_TYPE.PRO_PLUS && context.me?.subscription_plan === SUBSCRIPTION_PLAN.PRO_PLUS;
    const isSubscriptionPro =
      search?.subs === SUBSCRIPTION_TYPE.PRO && context.me?.subscription_plan === SUBSCRIPTION_PLAN.PRO;

    if (isSubscriptionPro || context.me?.subscription_plan === SUBSCRIPTION_PLAN.PRO_PLUS) {
      throw redirect({ to: from });
    }

    if (isSubscriptionProPlus) {
      throw redirect({ to: from });
    }
  },
  onEnter: () => {
    useLayoutState.getState().setTitle('Choose Your Plan');
  },
});
