import { ComponentPropsWithoutRef, forwardRef } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useDropdownContext } from '../dropdown.context';

import { cn } from '@/shared/libs/utils';
import { Icon } from '@/shared/ui';

export interface Props extends ComponentPropsWithoutRef<typeof DropdownMenu.CheckboxItem> {
  value: string;
}

export const CheckboxItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, children, value, ...restProps } = props;

  const { selectedValues, toggleValue } = useDropdownContext();

  const isChecked = selectedValues.includes(value);

  return (
    <DropdownMenu.CheckboxItem
      ref={ref}
      className={cn(
        'flex items-center justify-between px-4 py-2 text-base transition-color cursor-pointer rounded text-secondary hover:text-white focus:outline-none',
        className,
      )}
      onCheckedChange={() => toggleValue(value)}
      {...restProps}
    >
      <span>{children}</span>
      <DropdownMenu.ItemIndicator forceMount>
        <Icon
          k="check"
          className={cn('text-lg border border-grey-500 w-6 h-6 rounded text-transparent ml-1', {
            'text-black bg-brand-gradient': isChecked,
          })}
        />
      </DropdownMenu.ItemIndicator>
    </DropdownMenu.CheckboxItem>
  );
});
