import { useFeature } from '../api';
import { useFeatureGetter } from '../api';

export enum MonetizationFlagName {
  Default = '',
  ChatFreePhotoCoins = 'chat-free-photo-coins',
  ChatFreeCoinsPerImage = 'chat-free-coins-per-image',
}

export const useMonetizationFlagGetter = () => {
  return useFeatureGetter('monetization-flag', MonetizationFlagName.Default);
};

export const useMonetizationFlag = () => {
  return useFeature('monetization-flag', MonetizationFlagName.Default);
};
