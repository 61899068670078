import { AuthView, Props } from './auth-view';
import { DIALOG_KEY } from '@/core/constants';

import { useAuthVariant } from '@/widgets/auth-view/_model';

import { CloseDialogArgs, OpenDialogArgs, dialogControl } from '@/shared/ui/dialogs/model';
import { Modal } from '@/shared/ui/dialogs/pub';

const DialogAuthView = (props: Props) => {
  return (
    <Modal
      className="bg-black rounded-t-modal tablet:rounded-modal !h-[calc(100%-24px)] tablet:w-full tablet:max-w-[740px] tablet:max-h-[508px]"
      fullSize={{
        phone: true,
      }}
      closeBtnProps={{
        className: 'text-black bg-white/50 tablet:bg-white/30 tablet:text-white',
      }}
    >
      <AuthView {...props} />
    </Modal>
  );
};

export const dialogAuthView = {
  open: (params?: { props?: Props; options?: Omit<OpenDialogArgs, 'component' | 'key'> }) => {
    return dialogControl.open({
      key: DIALOG_KEY.AUTH_VIEW,
      component: <DialogAuthView {...(params?.props || {})} />,
      ...params?.options,
      afterClose: () => {
        useAuthVariant.getState().onSetSignUp();
        params?.options?.afterClose?.();
      },
    });
  },
  close: (options?: Omit<CloseDialogArgs, 'key'>) => {
    dialogControl.close({ key: DIALOG_KEY.AUTH_VIEW, ...options });
  },
};
