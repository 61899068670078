import { ChatEntity, ChatRepository } from '@repo/api/chat';
import { InnerUseQueryOptions } from '@repo/common/types/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const KEY = 'chats-list';

export const useGetChatsQuery = (options?: InnerUseQueryOptions<ChatEntity[]>) => {
  return useQuery<ChatEntity[]>({
    ...options,
    queryKey: [KEY],
    queryFn: () => ChatRepository.getChats(),
  });
};

export const useInvalidateChatsList = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries({ queryKey: [KEY] });
};

export const chatsListQueryKey = () => [KEY];
