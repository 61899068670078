import { ReactNode } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { FeaturesBookNotifier, FeaturesBookProvider } from '@repo/common/services/features-book';
import { useCreateTokenQuery } from '@repo/modules/entity/auth/queries';
import { authStorage } from '@repo/modules/entity/auth/services';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useSuspenseGetBrandConfig } from '@/entities/brand/queries/use-get-brand-config';

import { getFeaturesBook, postExperimentViewed } from '@/shared/api/requests';
import { Analytic } from '@/shared/services/analytic';
import { SplashScreen } from '@/shared/ui';

const readingNotifier: FeaturesBookNotifier = (_, value) => {
  if (!value?.experiment_key) return;
  dayjs.extend(utc);
  const timestamp = dayjs.utc().toISOString();

  return postExperimentViewed({
    experiment_key: value.experiment_key as string,
    variation_id: value?.experiment_variation_id as number,
    timestamp,
  });
};

export const AppInitialize = ({ children }: { children: ReactNode }) => {
  const brandQuery = useSuspenseGetBrandConfig({ orig: 'https://b2c.softpear' });
  const constants = brandQuery.data.payload?.constants;

  const initialData =
    authStorage.getAuth().token && authStorage.getAuth().sub
      ? {
          is_new_user: false,
          token: authStorage.getAuth().token as string,
          sub: authStorage.getAuth().sub as string,
          payload: null,
        }
      : undefined;

  const createTokenQuery = useCreateTokenQuery(
    { clientToken: constants?.api_token ?? '' },
    {
      retry: false,
      enabled: brandQuery.isSuccess,
      select: (data) => {
        Analytic.setUserId(data.sub);
        authStorage.setAuth({
          token: data.token,
          sub: data.sub,
        });
        return data;
      },
      initialData,
    },
  );
  const meQuery = useGetMeQuery({
    enabled: createTokenQuery.isSuccess,
  });

  if (createTokenQuery.isPending || brandQuery.isPending || meQuery.isPending) {
    return <SplashScreen />;
  }

  return (
    <FeaturesBookProvider featuresGetter={getFeaturesBook} readingNotifier={readingNotifier}>
      {constants?.firebase_web_keys?.client_id ? (
        <GoogleOAuthProvider clientId={constants.firebase_web_keys.client_id}>{children}</GoogleOAuthProvider>
      ) : (
        children
      )}
    </FeaturesBookProvider>
  );
};
