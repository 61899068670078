import { memo } from 'react';

import { useAuthVariant } from './_model';
import { Footer, Form, type FormProps, Header, Layout } from './_ui';

import { getAuthPicture } from '@/entities/auth/utils';
import { useGetBrandFeatures } from '@/entities/brand/hooks';
import { onBoardingStorageGetters } from '@/entities/onboarding/model';

export interface Props extends FormProps {}

export const AuthView = memo((props: Props) => {
  const { ...restProps } = props;

  const variant = useAuthVariant.use.variant();

  const features = useGetBrandFeatures();

  const authImage = getAuthPicture(features?.auth_content?.[variant] ?? null, onBoardingStorageGetters.getIntent());

  return <Layout form={<Form {...restProps} />} pictureSrc={authImage} footer={<Footer />} header={<Header />} />;
});
