import { useFormContext } from 'react-hook-form';

import { SignUpSchema } from '../_schema';
import { ApiError } from '@repo/api';
import { authStorage } from '@repo/modules/entity/auth/services';
import { invalidateGetMeQuery } from '@repo/modules/entity/user-queries';

import { useSignUpMutation } from '@/entities/auth/queries/use-sign-up';

import { queryClient } from '@/shared/api/query-client';

export interface Options {
  onBeforeSubmit?: () => Promise<void> | void;
  onSuccess?: () => Promise<void> | void;
  onError?: (error: unknown) => Promise<void> | void;
}

export const useSignUp = (options?: Options) => {
  const { onSuccess, onError, onBeforeSubmit } = options || {};

  const form = useFormContext<SignUpSchema>();

  const signUp = useSignUpMutation({
    onSuccess: async (data) => {
      authStorage.setToken(data.token);
      await onSuccess?.();
      await invalidateGetMeQuery(queryClient);
    },
    onError: async (error) => {
      await onError?.(error);
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      await onBeforeSubmit?.();
      await signUp.mutateAsync(data);
    } catch (error) {
      if (ApiError.is(error)) {
        form.setError('email', { message: error.message });
      }
      return Promise.reject(error);
    }
  });

  const isProcessingForm = signUp.isPending || form.formState.isSubmitting;

  return { form, onSubmit, isProcessingForm };
};
