import { useMemo } from 'react';

import { ProfileAction } from '../profile-action';
import { SubscribeAction } from '../subscribe-action';
import loadable from '@loadable/component';
import { useAllowedCreditFlow } from '@repo/modules/use-allowed-credit-flow';

import { cn } from '@/shared/libs/utils';

export interface Props {
  isHidden?: boolean;
}

export const LayoutActions = (props: Props) => {
  const { isHidden } = props;

  const allowedCreditFlow = useAllowedCreditFlow();

  const creditsActionSlot = useMemo(() => {
    if (!allowedCreditFlow) return null;
    const CreditsActionView = loadable(() => import('@/widgets/creadits-action-view'), {
      resolveComponent: (module) => module.CreditsActionView,
    });
    return <CreditsActionView />;
  }, [allowedCreditFlow]);

  return (
    <div
      className={cn('flex items-center gap-3 shrink-0', {
        'hidden tablet:flex': isHidden,
      })}
    >
      <SubscribeAction />
      {creditsActionSlot}
      <ProfileAction />
    </div>
  );
};
