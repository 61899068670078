import { useFormContext } from 'react-hook-form';

import { RecoverPasswordSchema } from '../_schema';
import { ApiError } from '@repo/api';

import { useRecoverPasswordMutation } from '@/entities/auth/queries/use-recover-password';
import { useGetBrandConstants } from '@/entities/brand/hooks';
import { FormActions } from '@/entities/form/types';

import { APP_ERRORS, AppError } from '@/shared/constants';

export interface Options extends FormActions<string> {}

export const useRecoverPassword = (options?: Options) => {
  const { onBeforeSubmit, onSuccess, onError } = options || {};

  const form = useFormContext<RecoverPasswordSchema>();

  const constants = useGetBrandConstants();

  const recoverPasswordMutation = useRecoverPasswordMutation({
    onSuccess: async () => {
      await onSuccess?.();
    },
    onError: async (error) => {
      await onError?.(error);
      if (ApiError.is(error)) {
        form.setError('email', { message: error.message });
      }
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      if (!constants?.api_token) {
        throw new AppError(APP_ERRORS.API_TOKEN_NOT_FOUND.message, APP_ERRORS.API_TOKEN_NOT_FOUND.code);
      }
      await onBeforeSubmit?.(data.email);
      await recoverPasswordMutation.mutateAsync({ ...data, clientToken: constants.api_token });
    } catch (error) {
      return Promise.reject(error);
    }
  });

  const isProcessingForm = recoverPasswordMutation.isPending || form.formState.isSubmitting;

  return { form, onSubmit, isProcessingForm };
};
