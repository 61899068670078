export enum EventPlacement {
  chat_coupons = 'chat_coupons',
  main_screen = 'main_screen',
  edit = 'edit',
  onboarding = 'onboarding',
  pro_dialogs = 'pro_dialogs',
  unblur = 'unblur',
  account_coupons = 'account_coupons',
  chats = 'chats',
  discover = 'discover',
  unknown = 'unknown',
  settings = 'settings',
  subscribe = 'subscribe',
  createAi = 'create-ai',
}

export enum MessageType {
  REGULAR = 'regular',
  SUGGESTED = 'suggested',
  PICTURE = 'picture',
}

export enum PaymentProvider {
  apple_pay = 'apple_pay',
  google_pay = 'google_pay',
  card = 'card',
}

export enum PaymentType {
  subscription = 'subscription',
  non_subscription = 'non_subscription',
}

export type Placement = {
  placement: EventPlacement;
};

export type PersonId = {
  persona_id: number;
  persona_name: string;
};

export type SendMessage = PersonId & {
  message_type: MessageType;
};

export type PaywallView = Partial<PersonId> & {
  placement: EventPlacement;
  type: PaymentType;
};

export type ProcessPayment = {
  payment_provider: PaymentProvider;
  item_id: string;
  price: number;
  currency: string;
};

export type PaymentEventPayload = {
  payment_provider: PaymentProvider;
  item_id: string;
  price: number;
  currency: string;
  placement: EventPlacement;
  type: PaymentType;
};

export type AddToCart = Partial<PersonId> & PaymentEventPayload;

export type PurchaseSuccessful = PaymentEventPayload;

export type PurchaseFailed = PaymentEventPayload & {
  error_description: string;
};
