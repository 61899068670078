import { ComponentPropsWithoutRef, forwardRef } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<typeof DropdownMenu.Content> {}

export const Content = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, children, ...restProps } = props;

  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        ref={ref}
        className={cn(
          'mt-2 bg-grey-600 rounded-md w-full py-3 z-[1000] w-full w-[var(--radix-dropdown-menu-trigger-width)]',
          className,
        )}
        {...restProps}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
});
