import { Outlet, createFileRoute } from '@tanstack/react-router';

import { useLayoutState } from '@/entities/layout/model';

import { Analytic, EventPlacement } from '@/shared/services/analytic';

export const Route = createFileRoute('/_onboarded/_layout/_settings')({
  component: Settings,
  onEnter: () => {
    useLayoutState.getState().setTitle('Settings');
    Analytic.setProperties({
      placement: EventPlacement.settings,
    });
  },
});

function Settings() {
  return (
    <div className="grow h-full">
      <Outlet />
    </div>
  );
}
