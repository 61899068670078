import { Layout, LayoutProps } from '../../ui';

import { mergeComponents } from '@/shared/libs/utils';

export interface Props extends LayoutProps {}

const ModalInner = (props: Props) => {
  const { ...restProps } = props;

  return <Layout {...restProps}></Layout>;
};

export const Modal = mergeComponents(ModalInner, {});
