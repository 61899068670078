import { SUBSCRIPTION_TYPE } from '../payment';

export enum PAYMENT_SYSTEM {
  STRIPE = 1,
  FIN_TM,
}

export enum CURRENCY_CODE {
  USD = 'USD',
  INR = 'INR',
}

export enum AUTH_VARIANT {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export enum AUTH_FLOW_VARIANT {
  NONE,
  AUTH_BEFORE_ACTION,
  AUTH_REQUIRED,
}

export enum BLUR_PICTURE_CONTENT {
  SIMPLE,
  COMPLEX,
}

export enum BLUR_PICTURE_INTENT {
  BLUR,
  GRADIENT,
}

export type FirebaseKeysModel = {
  client_id?: string;
};

export type BrandConfigEntity = {
  version: number;
  payload: BrandPayloadEntity;
};

export type BrandPayloadEntity = {
  constants: BrandConstantsEntity;
  // TODO: Add theme types
  theme: Record<string, unknown>;
  features: BrandFeaturesEntity;
  onboarding: {
    // TODO: Add onboarding types
    steps: any[];
  };
};

export type BrandConstantsEntity = {
  api_token: string;
  stripe_public_key: string;
  amplitude_key: string;
  google_tracking_id: string;
  google_tag_manager: string;
  firebase_web_keys: FirebaseKeysModel;
};

export type BrandFeaturesEntity = {
  app_name?: string;
  auth_modal?: FeatureAuthModalEntity;
  payment_system?: PAYMENT_SYSTEM;
  currency_code?: CURRENCY_CODE;
  auth_content?: Record<AUTH_VARIANT, AuthContentEntity>;
  auth_flow?: AUTH_FLOW_VARIANT;
  credit_modal: FeatureCreditModalEntity;
  credit_button_content?: string | FeatureIconEntity;
  premium_badge_content?: string | FeatureIconEntity;
  blur_image_intent?: BLUR_PICTURE_INTENT;
  blur_image_content?: BLUR_PICTURE_CONTENT;
  modal_blurred_info: FeatureModalWithBluerEntity;
  change_face_btn?: boolean;
  change_face_edit_content?: FeatureChangeFaceBtnContent;
  subscription_appearance_variant?: 'badge' | 'text';
  subscription_btn_icon?: FeatureIconEntity;
  logo?: FeatureLogoEntity;
  subscription_image?: FeatureSubscriptionModalEntity;
  settings_subscription?: FeatureSettingsViewEntity;
  ab_test?: boolean;
  deep_link?: boolean;
  documents?: BrandDocumentsEntity;
  subscription_benefits?: Record<SUBSCRIPTION_TYPE, FeatureSubscriptionBenefitsEntity[]>;
  third_party?: FeatureThirdPartyEntity;
  documents_links?: BrandDocumentsLinksEntity[];
  footer?: BrandFooterEntity;
};

export type AuthContentEntity = {
  pictures: AuthPicturesEntity;
};

export type AuthPicturesEntity = {
  default: string;
  intent: Record<string, string>;
};

export type FeatureThirdPartyEntity = {
  red_track?: {
    src: string;
  };
};

export type FeatureSubscriptionBenefitsEntity = {
  key: string;
  label: string;
  icon: string;
};

export type SubscriptionBenefitEntity = {
  pro: string;
  pro_plus: string;
};

export type BrandDocumentsEntity = {
  terms_of_use: string;
  banned_policy: string;
  contact_email: string;
};

export type BrandDocumentsLinksEntity = {
  to: string;
  key: string;
  label: string;
};

export type FeatureSettingsViewEntity = {
  desktop: string;
  mobile: string;
};

export type FeatureSubscriptionModalEntity = {
  pro: string;
  pro_plus: string;
};

export type FeatureLogoEntity = {
  root: string;
  img_text: string;
  favicon: string;
};

export type FeatureChangeFaceBtnContent = {
  text?: string;
  icon?: string;
};

export type FeatureAuthModalEntity = {
  LOGIN: string;
  SIGNUP: string;
};

export type FeatureModalWithBluerEntity = {
  icon: boolean;
  title: string;
  text: string;
};

export type FeatureCreditModalEntity = {
  title: string;
  banner: FeatureCreditModalBannerEntity;
  image_url: string;
  credit_icon_type: string;
  discount_placement: 'top' | 'bottom';
};

export type FeatureCreditModalBannerEntity = {
  title: string;
  img_bg?: string;
};

export type FeatureIconEntity = {
  type: 'icon';
  icon: string;
};

export type GetBrandConfigQuery = {
  orig?: string;
};

export type BrandFooterEntity = {
  description: string;
  copyright_text: string;
};
