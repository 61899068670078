import { authStorage } from '@repo/modules/entity/auth/services';
import { useInvalidateChatsList } from '@repo/modules/entity/chat-queries';
import { useInvalidateCharacterListQuery } from '@repo/modules/entity/chat-queries';
import { useInvalidatedGetMeQuery } from '@repo/modules/entity/user-queries';
import { useGoogleSignUp } from '@repo/modules/feature/auth/api/goole-sign-up';

import { Firebase } from '@/shared/services/firebase';

export interface GoogleSignUpFormControllerParams {
  onBeforeSubmit?: () => Promise<void> | void;
  onSuccess?: () => Promise<void> | void;
  onError?: (error: unknown) => Promise<void> | void;
}

export const useGoogleSignUpFormController = (params?: GoogleSignUpFormControllerParams) => {
  const { onSuccess, onError, onBeforeSubmit } = params || {};
  const { onGoogleSignUp } = useGoogleSignUp();

  const invalidates = [useInvalidateChatsList(), useInvalidatedGetMeQuery(), useInvalidateCharacterListQuery()];

  const onSignUp = async (credential: any) => {
    await onBeforeSubmit?.();
    const token_id = await Firebase?.getCurrentUserToken(credential);

    try {
      const response = await onGoogleSignUp({ token_id });
      authStorage.setToken(response.token);
      await Promise.all(invalidates.map((invalidate) => invalidate()));
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    }
  };

  return {
    onSignUp,
  };
};
