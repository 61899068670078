import { AUTH_VARIANT } from '@repo/api/brand';
import { create } from 'zustand';

import { createSelectors } from '@/shared/libs/zustand-helpers';

interface State {
  variant: AUTH_VARIANT;
}

interface Actions {
  onSetSignIn: () => void;
  onSetSignUp: () => void;
  onSetVerifyEmail: () => void;
  onSetResetPassword: () => void;
  onSet: (variant: AUTH_VARIANT) => void;
}

const defaultValue: State = {
  variant: AUTH_VARIANT.SIGN_UP,
};

const useAuthVariantBase = create<State & Actions>()((set) => ({
  ...defaultValue,
  onSetSignIn: () => set({ variant: AUTH_VARIANT.SIGN_IN }),
  onSetSignUp: () => set({ variant: AUTH_VARIANT.SIGN_UP }),
  onSetVerifyEmail: () => set({ variant: AUTH_VARIANT.VERIFY_EMAIL }),
  onSetResetPassword: () => set({ variant: AUTH_VARIANT.RESET_PASSWORD }),
  onSet: (variant: AUTH_VARIANT) => set({ variant }),
}));

export const useAuthVariant = createSelectors(useAuthVariantBase);
