import { useRecoverPassword } from '@/features/recover-password-form/_model';
import { RecoverPasswordSchema, recoverPasswordSchema } from '@/features/recover-password-form/_schema';

import { FormActions } from '@/entities/form/types';
import { FormInput } from '@/entities/form/ui/field';
import { SubmitBtn } from '@/entities/form/ui/submit-btn';

import { withFormProvider } from '@/shared/hoc';
import { FormLayout } from '@/shared/ui/layouts/form-layout';

export interface Props extends FormActions<string> {}

const defaultValues: RecoverPasswordSchema = {
  email: '',
};

const _RecoverPasswordForm = (props: Props) => {
  const { onSuccess, onError, onBeforeSubmit } = props;

  const { form, onSubmit, isProcessingForm } = useRecoverPassword({ onSuccess, onError, onBeforeSubmit });

  return (
    <FormLayout onSubmit={onSubmit} action={<SubmitBtn label="Send" isProcessing={isProcessingForm} />}>
      <FormInput
        control={form.control}
        name="email"
        label="Email"
        placeholder="Enter your email"
        autoComplete="off"
        error={form.formState?.errors?.email?.message}
      />
    </FormLayout>
  );
};

export const RecoverPasswordForm = withFormProvider(_RecoverPasswordForm, recoverPasswordSchema, defaultValues);
